.pac-container {
    z-index: 100000;
    .pac-item {
        padding: 5px 15px !important;
        &:hover {
            cursor: pointer;
        }
    }
}

.pac-container::after {
    display: none !important;
    
}
