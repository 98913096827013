.page-container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px 40px;
  display: block;
  @include themed() {
    background-color: t($bg);
  }
}

.page-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & h1 {
    display: none;
  }
  &-text {
    @include themed() {
      color: t($primary-color);
    }
  }
}

.page-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  place-items: flex-start;

  gap: 2rem;
  padding: 1rem 0;
  margin: 1rem 0;

  > h1 {
    place-items: flex-start;
  }
}

.home-page-yourcard {
  padding: 0;
}
