.input-addcompany {
  font-size: 16px;
  padding: 20px 12px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}
.selectinput-addcompany {
  font-size: 16px;
  // padding: 20px 12px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}
.phoneinput-addcompany {
  font-size: 16px;
  padding: 20px 12px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}
.react-tel-input .form-control {
  // padding: 20px 12px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 34px !important;
  position: relative;
  letter-spacing: 0.01rem;
  // border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.formrow-addcompany {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
}

.formlayout-addcompany {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.formcolumn-addcompany {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  width: 100%;
}
