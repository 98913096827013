// Main Color Theme
$eerie-black: #282828;
$electric-blue: #52d9ff;
$flame: #e4572e;
$medium-champagne: #e1d89f;
$middle-grey: #85877c;
$white: #fff;

// UI Colors
$success-color: #29cc97;
$danger-color: #fd4d4d;
$warning-color: #fec810;
$grey: #6c6c72;

// Others
$vscode-blue: #0078d7;
