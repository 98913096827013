.form-switch {
  padding-left: 2vw;
  transform: translateY(8px);
  &-small {
    padding-left: 20px;
    transform: translateY(0px);
  }
}
.label-small {
  font-size: 0.875rem;
  padding: 5px 12px -2px 16px;
}

.input-small {
  width: 30%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #999;
  // border-radius: 5px;
  color: #333;
  display: block;
  font-size: 0.875rem;
  margin: 0 0 0 20px;
  outline: 0;
  padding: 8px 10px;
  overflow: hidden;
}

.mcc-verified {
  font-size: 0.75rem;
  text-align: right;
  padding-left: 10px;
  // position: absolute;
  // right: 8vw;
  // transform: translateY(2px);
}
.phone-input {
  padding: 10px 0;
  width: 70%;
  // margin-left: 15%;
  @include mediaMd {
    width: 100%;
    // margin-left: 0;
  }
}

.current-role-select-role {
  font-size: 0.875rem;
  @include responsiveMax($iPhone5) {
    width: 150px;
  }
}

@include responsiveMax($iPhone5) {
  .form {
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 30px 0 0;

      h2 {
        padding: 0;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
    &-x-btn {
      padding-right: 15px;
    }
    &-nav-bottom {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      top: auto;
      padding: 0;
      margin: 5vh 0;
    }
    &-submit-btn {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      top: auto;
      margin: 5vh 0 0 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
@include responsiveMax($GalaxyS5) {
  .form {
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 30px 0 0;

      h2 {
        padding: 0;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
    &-x-btn {
      padding-right: 15px;
    }
    &-nav-bottom {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      top: auto;
      padding: 0;
      margin: 5vh 0;
    }
    &-submit-btn {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      top: auto;
      margin: 5vh 0 0 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
@include responsiveMax($Pixel2) {
  .form {
    &-submit-btn {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      top: auto;
      margin: 5vh 0 0 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
