.spinner-container {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.logo-spinner {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;

  margin: auto;
}

.logo-spinner-img {
  display: block;
  width: 60px;
  margin: auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.unlinked-account {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;
  &-text {
    // display: block;
    // margin: auto;
    padding-bottom: 20px;
  }
  &-subtext {
    padding-bottom: 20px;
  }
}

//MFA Container
.mfa-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px;
  height: 100%;
  min-height: 100vh;
  background-color: #dfe0eb;
  @include mediaSm {
    padding: 0;
  }
}
.mfa-widget-container {
  display: flex;
  flex-direction: column;
  height: auto; //716px; // set to auto
  width: 400px;
  white-space: normal;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 40px;
  @include mediaSm {
    padding: 0;
    width: 100%;
    border-radius: 0px;
  }
}
.mfa-container {
  &-header {
    padding: 40px 40px 24px;
    line-height: 1.5;
    text-align: center;
    flex-shrink: 0;
    &-item {
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 100%;
      vertical-align: baseline;
    }
    &-title {
      margin: 24px 0;
      font-size: 24px;
    }
    &-text {
      font-size: 14px;
      color: #282828;
    }
  }
  &-logo {
    max-height: 52px;
    max-width: 100%;
    vertical-align: top;
  }

  &-input-item {
    padding: 0px 40px 30px;
    text-align: center;
    flex-grow: 0;
  }
  &-input-phone {
    // display: flex;
    // align-items: center;
    width: 100%;
    color: #2d333a;
    padding: 16px;
  }
}
.mfa-button-submit {
  width: 100%;
  background: #282828;
  color: white;
  padding: 20px;
  margin: 10px 0;
  border: 0;
  border-radius: 3px;
  font-size: 16px;
}

.mfa-container-input-code {
  font-size: 16px;
  border: 1px solid #c2c8d0;
  height: 52px;
  line-height: 1.1;
  outline: none;
  padding: 0 16px;
  width: 100%;
  background-color: white;
  transition: {
    transition-property: box-shadow, border-color 0.2s ease-in-out;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
  }
  border-radius: 3px;
}
// Items inside MFA column flex container
.mfa-browser-check {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;
  &-text {
    // display: block;
    // margin: auto;
    padding-bottom: 20px;
  }
  &-subtext {
    padding-bottom: 20px;
  }
}
