@include mediaMd {
  .form {
    &-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 0;
      // padding: 20px 0;
    }

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 30px 0 0;

      h2 {
        padding: 0;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
    &-x-btn {
      position: relative;
    }

    &-nav {
      width: 100%;
      padding: 0;
      margin-top: 10px;
    }
    &-submit-btn {
      width: 100%;
      text-align: center;
      top: 65%;
      margin: 5vh 0;
      padding: 0;
      text-decoration: underline;
    }
    &-continue-btn {
      text-decoration: underline;
      font-weight: 700;
      margin-left: 0;
      padding-left: 5vw;
    }
    &-goback-btn {
      margin-bottom: 10px;
    }

    &-responsive-row {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
    }
  }

  .form-field__input {
    &:focus,
    &:not(:placeholder-shown) {
      // @include label-inactive;
      & + .form-field__label {
        color: $primary-color;
        @include label-active;
        & + .form-field__bar {
          border-bottom: 2px solid $secondary-color;
          transform: scaleX(100);
        }
      }
    }
  }

  .form-field__select {
    &:focus,
    &:not(:placeholder-shown) {
      // @include label-inactive;
      & + .form-field__label {
        color: $primary-color;
        transform: translateY(-7vh);
        font-size: 1rem;
        & + .form-field__bar {
          border-bottom: 2px solid $secondary-color;
          transform: scaleX(100);
        }
      }
    }
  }

  .form-select {
    width: 70%;
    margin-left: 15%;
    margin-top: 24px;
    padding: 0;
    &-small {
      width: 70%;
      // margin-left: 15%;
      margin-top: 5px;
    }
  }

  .form-switch {
    padding-left: 4vw;
    transform: translateY(5px);
  }

  .mcc-verified {
    font-size: 0.75rem;
    text-align: right;
    padding-right: 20px;
    // transform: translateY(2px);
    // position: absolute;
    // right: 8vw;
  }

  .phone-input {
    padding: 10px 0;
  }
}
