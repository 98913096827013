.form-container {
  margin: 0;
  // display: flex;
  // flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;

  &-item {
    // display: flex;
    // flex-direction: column;
    margin: 0;
    margin-top: 5vh;
    text-align: left;
  }
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 6px 0 12px;
    &-responsive {
      display: flex;
      flex-direction: column;
      align-items: left;
      margin: 6px 0 12px;
    }
    & p {
      min-width: 55%;
      max-width: 55%;
      font-size: 14px;
      padding: 0 0 0 16px;
    }
  }
  @include responsiveMin($desktopMin) {
    &-item {
      display: flex;
      flex-direction: column;
      margin-top: 5vh;
    }
    &-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      &-responsive {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }
      & p {
        min-width: 70%;
      }
    }
  }
}
