.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.paginator-control {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #626668;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 8px;
  &:hover {
    background-color: darken(#fff, 5);
    // border: 1px solid #d7dbdb;
  }
  &:disabled {
    background-color: #d7dbdb;
    // border: 1px solid darken(#d7dbdb, 20);
    cursor: default;
  }
}
.paginator-button {
  background-color: #fff;
  border: 1px solid #d7dbdb;
  color: #818487;
  border-radius: 3px;
  font-size: 20px;
  margin: 0.25rem 0.5rem;
  padding: 5px 5px 3px 5px;
  &-showall {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #d7dbdb;
    color: #818487;
    border-radius: 3px;
    font-weight: 700;
    font-size: 12px;
    margin-left: 0.5rem;
    padding: 5px 10px 3px 10px;
    &:hover {
      background-color: darken(#fff, 5);
      border: 1px solid darken(#d7dbdb, 20);
    }
    &:disabled {
      background-color: #d7dbdb;
      border: 1px solid darken(#d7dbdb, 20);
      cursor: default;
    }
  }
}
.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.sort-control {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #626668;
  padding: 0 5px 0 0;
}
.sort-button {
  background-color: #fff;
  color: #818487;
  font-size: 20px;
  margin-left: 0.5rem;
  padding: 5px 0;
}

.warning-bar {
  border: 1px solid $warning-color;
  width: 100%;
  height: 0;
  margin: 0;
}

.selector-row {
  display: flex;
}
.selector-column {
  display: block;
}

.selector {
  display: inline;
}

.selector-bar {
  border: 1px solid $light-grey-color;
  width: 100%;
  height: 0;
  margin: 0;
}

.half {
  width: 50%;
}
.ninety {
  width: 90%;
}

@include mediaMd {
  .paginator {
    padding: 10px 0;
    margin-bottom: 0 !important;
  }
  .paginator-button {
    margin: 0 0.5rem;
    padding: 5px 10px 3px 10px;
  }
  .sort {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  .sort-control {
    padding: 0 5px 0 10px;
  }
}
