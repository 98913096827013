.credit-indicator {
  &-container {
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 100px;
    // right: 10px;
    padding: 0 0 0 0;
    justify-content: space-between;
    width: 300px;
    // background-color: #fbfafa;
    // border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: none;
    // flex-wrap: wrap;
    font-size: 14px;
    @include responsiveMin($desktopMin) {
      flex-direction: column;
      padding: 0 0 5px 43px;
      // display: flex;
      // flex-direction: column;
      text-align: left;
      // align-items: left;
      width: 400px;
      // background-color: #fbfafa;
      // border: 1px solid #dcdcdc;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  &-row {
    // padding: 0 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // @include responsiveMin($desktopMin) {
    //   width: 100%;
    //   padding: 10px 0;
    //   display: flex;
    //   flex-direction: row;
    // }
  }
  &-title {
    text-align: left;
    width: fit-content;
    font-size: 16px;
    padding-bottom: 5px;
    // width: fit-content;
    // @include responsiveMin($desktopMin) {
    //   text-align: right;
    //   //   width: 143px;
    //   width: fit-content;
    // }
  }
  &-label {
    text-align: left;
    width: fit-content;
    font-size: 14px;
    color: $med-grey-color;
    padding-left: 1.3rem;
    // width: fit-content;
    // @include responsiveMin($desktopMin) {
    //   text-align: right;
    //   //   width: 143px;
    //   width: fit-content;
    // }
  }
  &-value {
    font-size: 16px;
    color: $primary-color;
    padding-left: 10px;
    // @include responsiveMin($desktopMin) {
    //   font-size: 14px;
    //   color: $primary-color;
    // }
  }
}
