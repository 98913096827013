// material design
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-content {
  margin: 10% auto;
  border-radius: 5px;
  //mdl shadow
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  //mdl card
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  max-width: 500px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;

  @include mediaSm {
    max-width: 330px;
  }
}

.mdl_card-title {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
}

.mdl-card__actions {
  background-color: rgba(222, 222, 222, 0.25);
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
  &-form {
    padding: 10px 0 0;
    position: relative;
    align-self: flex-end;
  }
}

.mdl-title {
  padding-right: 20px;
}

.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: auto;
  min-width: 64px;
  padding: 0 0 0 20px;
  display: block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.mdl-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

// .mld-form-field {
// }

// OLD

// .modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: none;
// background: rgba(0, 0, 0, 0.6);

// .modal-main {
//   position: fixed;
//   background: white;
//   width: 50%;
//   height: auto;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   max-height: 100%;
//   @include responsiveMinMax($desktopMin, $Xldesktop) {
//     width: 80%;
//   }
// }

// .budget-modal {
//   overflow-y: scroll;
//   @include mediaMd {
//     padding: 5vh 0;
//     overflow-y: scroll;
//   }
// }

.display-block {
  display: block;
  z-index: 10;
}

.display-none {
  display: none;
}

.modal-form-main {
  width: 100%;
}

.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;

  font-weight: 700;
  cursor: pointer;
  color: #282828;
  background-color: #85877c;
  border-radius: 4px;
  border: 1px solid #85877c;
  font-size: 16px;
  padding: 5px 20px;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
  box-shadow: inset 0 -1px 0 0 #85877c;
  text-transform: capitalize;
  &-receipt {
    display: block;
    margin: 10px auto;
    font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: #85877c;
    border-radius: 4px;
    border: 1px solid #85877c;
    font-size: 16px;
    padding: 5px 20px;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
    box-shadow: inset 0 -1px 0 0 #85877c;
    text-transform: capitalize;
  }
  &-budget {
    position: relative;
    display: block;
    margin: 10px auto;
    // font-weight: 700;
    cursor: pointer;
    // color: #282828;
    // background-color: #85877c;
    // border-radius: 4px;
    // border: 1px solid #85877c;
    // font-size: 16px;
    padding: 5px 20px;
    transform: translateY(0);
    // transition: transform 150ms, box-shadow 150ms;
    // background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
    // box-shadow: inset 0 -1px 0 0 #85877c;
    // text-transform: capitalize;
  }
}

@include mediaMd {
  // .modal {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.6);

  // .modal-main {
  //   position: fixed;
  //   background: white;
  //   width: 95%;
  //   height: auto;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   padding-bottom: 10px;
  // }
  // }

  .display-block {
    display: block;
    z-index: 10;
  }

  .display-none {
    display: none;
  }

  .modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: #85877c;
    border-radius: 4px;
    border: 1px solid #85877c;
    font-size: 16px;
    padding: 5px 20px;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
    box-shadow: inset 0 -1px 0 0 #85877c;
    text-transform: capitalize;
    &-receipt {
      display: block;
      margin: 10px auto;
      font-weight: 700;
      cursor: pointer;
      color: #282828;
      background-color: #85877c;
      border-radius: 4px;
      border: 1px solid #85877c;
      font-size: 16px;
      padding: 5px 20px;
      transform: translateY(0);
      transition: transform 150ms, box-shadow 150ms;
      background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
      box-shadow: inset 0 -1px 0 0 #85877c;
      text-transform: capitalize;
    }
  }
}
