.portal-header-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 20px 0 5px 0;
  border-bottom: 3px solid #e8e9e9;
}

.portal-header-tab {
  display: inline-block;
  background-color: #fff;
  color: #babdbe;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: -3px;
}

.portal-tab {
  border-bottom: 3px solid #e8e9e9;
  padding: 8px 24px 8px 24px;
  display: block;
  color: inherit;
  text-decoration: inherit;
  white-space: nowrap;
}

.portal-tab-active {
  background-color: #fff;
  border-bottom: 3px solid #52d9ff;
  color: #282828;
}
.portal-page-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.addcompany-submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.addcompany-btn {
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  background-color: #e4572e;
  border-radius: 4px;
  border: 1px solid darken(#e4572e, 10);
  font-size: 16px;
  padding: 10px 30px;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  background-image: linear-gradient(
    -180deg,
    #e4572e 0,
    darken(#e4572e, 5) 100%
  );
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
}

@include mediaMd {
  .portal-header-tabs {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 20px 0 5px 0;
    border-bottom: 3px solid #e8e9e9;
  }
  .portal-header-tab {
    display: inline-block;
    background-color: #fff;
    color: #babdbe;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: -3px;
  }
}
