//Generic Form Elements
.plain-form {
  &-container {
    position: relative;
    top: 12vh;
    left: 20%;
  }
  &-column {
    display: flex;
    flex-direction: column;
    margin-right: 5vw;

    &-left {
      display: flex;
      flex-direction: column;
      margin-right: 3vw;
      text-align: right;
    }
    &-right {
      display: flex;
      flex-direction: column;
      margin-right: 3vw;
      text-align: left;
    }
  }
  &-row {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
  }
  &-input {
    border: 1px solid #999;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin: 2vh 0 0.5vh 0;
    outline: 0;
    padding: 5px 12px 5px 16px;
    width: 100%;
    overflow: hidden;
    // transform: translateY(5vh);
  }
  &-label {
    font-size: 1rem;
  }
  &-text {
    font-size: 1.2rem;
    margin: 2vh 0;
  }
  &-nav-btn {
    text-align: center;
  }
}
