//shortcuts
$bg: "bg";
$nav-bg: "nav-bg";
$line-bg: "line-bg";
$card-bg: "card-bg";
$text: "text";
$text-secondary: "text-secondary";
$link: "link";
$hover: "hover";
$logo: "logo";
$brand-blue: "brand-blue";
$brand-red: "brand-red";
$border: "border";
$box-shadow: "box-shadow";
$primary-color: "primary-color";
$secondary-color: "secondary-color";
$greeting-text: "greeting-text";
$greeting-text-small: "greeting-text-small";
$btn-p: "btn-p";
$btn-box-shadow: "btn-box-shadow";

//themes
$themes: (
  default: (
    logo: url("../../assets/vlogo.svg"),
    bg: $bg--light,
    nav-bg: $nav-bg--light,
    line-bg: $line-bg--light,
    // card-bg: $bg--card--light,
      // text: $text--light,
      // text-secondary: $text--2--light,
      // link: $brand--blue--light,
      // hover: $hover--light,
      // border: $border--light,
      box-shadow: $box-shadow--light,
    primary-color: $primary-color,
    secondary-color: $secondary-color,
    greeting-text: $greeting-text--light,
    greeting-text-small: $greeting-text-small--light,
    btn-p: $btn-p--light,
    btn-box-shadow: $btn-box-shadow--light,
  ),
  dark: (
    logo: url("../../assets/vlogo.svg"),
    bg: $bg--dark,
    nav-bg: $nav-bg--dark,
    line-bg: $line-bg--dark,
    // card-bg: $bg--card--dark,
      // text: $text--dark,
      // text-secondary: $text--2--dark,
      // link: $brand--blue--dark,
      // hover: $hover--dark,
      // border: $border--dark,
      box-shadow: $box-shadow--dark,
    primary-color: $primary-color--dark,
    secondary-color: $secondary-color--dark,
    greeting-text: $greeting-text--dark,
    greeting-text-small: $greeting-text-small--dark,
    btn-p: $btn-p--dark,
    btn-box-shadow: $btn-box-shadow--dark,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
