// Card List Item
.cardpage-autobudget-list-item {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  // border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  padding-left: 10%;

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  &-categoryname {
    flex: 0 0 25%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
  }
  &-budgetname {
    flex: 0 0 40%;
    padding: 0 10px;
    font-weight: 600;
  }
  &-remainingbudget {
    color: #29cc97;
    flex: 0 0 20%;
    padding: 0 10px;
    font-weight: bold;
    &-text {
      padding: none;
      color: rgba(54, 55, 64, 0.5);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
    &-approval {
      color: $warning-color;
      flex: 0 0 5%;
      padding: 5px;
    }
    &-active {
      color: #29cc97;
      flex: 0 0 5%;
      // padding: 5px;
    }
    &-inactive {
      color: #fd4d4d;
      flex: 0 0 5%;
      // padding: 5px;
      /* font-weight: bold; */
    }
  }
  &-maxswipe {
    color: #fd4d4d;
    flex: 0 0 20%;
    padding: 0 10px;
    font-weight: bold;
    &-text {
      padding: none;
      color: rgba(54, 55, 64, 0.5);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
    }
    &-approval {
      color: $warning-color;
      flex: 0 0 5%;
      padding: 5px;
    }
    &-active {
      color: #29cc97;
      flex: 0 0 5%;
      // padding: 5px;
    }
    &-inactive {
      color: #fd4d4d;
      flex: 0 0 5%;
      // padding: 5px;
      /* font-weight: bold; */
    }
  }
}

.card-autobudget-list {
  margin-bottom: 20px;
}

.card-list-item-autobudget-empty {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mediaMd {
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
  @include responsiveMax($iPhone5) {
    min-width: 320px;
  }
}

@include mediaMd {
  /* Designate Grid Items */
  .cardpage-autobudget-list-item {
    &-categoryname {
      grid-area: categoryname;
      padding: 0 10px 0 0;
      font-size: 16px;
      font-weight: 600;
      overflow-x: hidden;
      overflow-y: none;
      word-wrap: break-word;
      // width: 250px;
      height: 25px;
      // word-break: break-all;
      //   min-width: 250px;
      //   max-height: 25px;
    }
    &-cardnumber {
      // grid-area: cardnumber;
      color: gray;
      padding: 0 5px 0 10px;
      font-size: 14px;
      overflow: hidden;
      min-width: 100%;
      max-height: 25px;
    }
    &-cardstatus {
      grid-area: cardstatus;
      padding: 0;
      font-size: 16px;
      text-align: left;
      overflow: hidden;
      max-width: 20px;
    }
    &-budgetname {
      padding: 0;
      grid-area: budgetname;
      text-align: left;
      font-size: 14px;
      color: #59a1c9;
    }
    &-remainingbudget {
      text-align: right;
      grid-area: remainingbudget;
      font-weight: bold;
      font-size: 16px;
      color: #29cc97;
      padding: none;
      &-text {
        padding: none;
        color: rgba(54, 55, 64, 0.5);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }
    }
    &-maxswipe {
      text-align: right;
      grid-area: maxswipe;
      font-weight: bold;
      font-size: 16px;
      color: #fd4d4d;
      padding: none;
      &-text {
        padding: none;
        color: rgba(54, 55, 64, 0.5);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
      }
    }

    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      'categoryname remainingbudget maxswipe'
      'budgetname   remainingbudget maxswipe';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 43px;
    min-width: 375px;
  }
}
@include responsiveMax($iPhone5) {
  .cardpage-autobudget-list-item {
    min-width: 320px;
  }
}
