.formik-form-select {
  font-family: 'Muli', sans-serif;
  background-color: #fff;
  border-radius: 4px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(22, 22, 51, 0.145);
  border-image: initial;
  overflow: hidden;
  transition: border-color 0.2s ease 0s;
  width: 100%;
  // margin-left: 15%;
  // margin-top: 24px;
  padding: 10px 6px;
  font-size: 0.875rem;
  // @include mediaMd {
  //   width: 90%;
  // }
}
.formik-input-mtop {
  margin-top: 1rem;
}
.formik-max-width {
  @include responsiveMin($desktopMin) {
    max-width: 700px;
  }
  @include mediaMd {
    margin-left: 10%;
    width: 80%;
  }
}

// .formik-select-container {
//   @include mediaMd {
//     padding-left: 10%;
//   }
// }
.formik-step-mleft {
  @include mediaMd {
    margin-left: 10%;
  }
}

.online-purchase-heading {
  font-size: 1.25rem;
  margin: 1rem 1rem 1rem 0;
  @include mediaMd {
    margin: 2rem auto;
  }
}
.online-purchase-btn-container {
  display: flex;
  flex-direction: row;
  @include mediaMd {
    flex-direction: column;
    width: 100%;
    align-content: center;
  }
}
.online-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  @include mediaMd {
    margin-bottom: 2.5rem;
    padding: 1.25rem 2.5rem;
    font-size: 1.5rem;
  }
}

.btn-selected-value {
  border-width: 2px;
  font-weight: bold;
}

// .btn-select-row {
//   @include mediaMd {
//     width: 100%;
//     display: flex;
//     justify-content: space-around;
//   }
// }

.btn-select {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 1.8rem;
  font-size: 1.1rem;
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  @include mediaMd {
    margin: 1rem 1rem 2rem 0;
    padding: 1.2rem 2rem 1.2rem 1.2rem;
    font-size: 1.2rem;
    width: 125px;
  }
}

.or-text-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 80%;
  margin: 1rem 0;
  text-align: center;
  @include mediaMd {
    width: 100%;
    margin: 2vh auto;
  }
}
.or-text {
  margin: 0 1rem;
}

.category-purchase-btn-container {
  display: flex;
  flex-direction: row;
  @include mediaMd {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.category-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  @include mediaMd {
    margin: 1rem 0 2.5rem 0;
    padding: 1.1rem 2rem;
    font-size: 1rem;
  }
}

.amount-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  @include mediaMd {
    margin: 1.5rem 0 1.5rem 0;
    padding: 1.1rem 1.2rem;
    font-size: 1.1rem;
  }
}

.amount-input-width {
  max-width: 200px;
  @include mediaMd {
    max-width: none;
    margin-right: 2rem;
  }
}

.description-editor {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: text;
  min-height: 200px;
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  /* display: block;
  position: relative; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-family: 'Muli', sans-serif;
  line-height: 1.5em;
  width: 100%;
  letter-spacing: normal;
  font-size: 18px;
}
@media (min-width: 769px) {
  .description-editor {
    border: 1px solid #ccc;
    cursor: text;
    min-height: 80px;
    padding: 30px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    display: block;
    position: relative;
    border-radius: 4px;
  }
}

.description-editor-container {
  padding: 10px 20px;
  // width: 100%;
  text-align: center;
}
@media (min-width: 769px) {
  .description-editor-container {
    padding: 20px;
    width: 700px;
  }
}

.description-width {
  max-width: 130px;
  max-height: 20px;
  overflow-y: hidden;
}
@media (min-width: 769px) {
  .description-width {
    max-width: none;
  }
}
