.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  @include responsiveMax($iPhone5) {
    font-size: 14px;
  }
}

.account-profile-pic {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 60px;
  height: 60px;
  margin: 0 10px 0 0;
}

.card-detail-preview {
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  margin: 0 0 20px 20px;
  border-bottom: 1px solid lightgrey;
  background: lighten(#f6f6f6, 2%);
  @include mediaMd {
    padding: 10px;
    margin: 0;
  }
}

.active-auto-expense {
  color: $success-color;
  padding-left: 15px;
  font-weight: bold;
}
