// Function to Check List Type and Initialize Classes
@mixin checkListType($listType) {
  // Transactions List
  @if $listType == transactions {
    .new-list-item {
      display: grid;
      grid-template-columns: 100px 4fr 1fr 1.2fr 3fr 60px;
      grid-template-areas: 'purchaser merchantName status amount budgetName date';
      grid-auto-rows: minmax(3.5rem, auto);
      gap: 0.5rem;

      &-name {
        grid-area: merchantName;
      }
      &-description {
        grid-area: budgetName;
      }
      &-avatar-pic {
        grid-area: purchaser;
      }
      &-status {
        grid-area: status;
      }
    }
    @include mediaMd {
      .new-list-item {
        /* Set Up Grid */
        grid-template-columns: 2fr 1.2fr;
        grid-template-areas:
          'purchaser      date'
          'merchantName amount'
          'budgetName   status';
        gap: 1px;
        grid-auto-rows: minmax(auto, 1.5rem);
        grid-template-rows: auto auto auto;
      }
    }
  }
  //Budget Transaction List
  @else if $listType == budgetTransactions {
    .new-list-item {
      display: grid;
      grid-template-columns: 100px 1.5fr 0.5fr 0.5fr 1fr 1fr 60px;
      grid-template-areas: 'purchaser merchantName status amount description costCode date';
      grid-auto-rows: minmax(3.5rem, auto);
      gap: 0.5rem;

      &-name {
        grid-area: merchantName;
      }
      &-description {
        grid-area: budgetName;
      }
      &-avatar-pic {
        grid-area: purchaser;
      }
      &-status {
        grid-area: status;
      }
      &-transactionDescription {
        grid-area: description;
      }
      &-costCode {
        grid-area: costCode;
      }
    }
    @include mediaMd {
      .new-list-item {
        /* Set Up Grid */
        grid-template-columns: 2fr 1.2fr;
        grid-template-areas:
          'purchaser       date'
          'merchantName  amount'
          'budgetName    status'
          'description costCode';
        gap: 1px;
        grid-auto-rows: minmax(auto, 1.5rem);
        grid-template-rows: auto auto auto;
      }
    }
  }
  // Budgets List
  @else if $listType == budgets {
    .new-list-item {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 5fr 0.5fr;
      grid-template-areas: 'budgetName status refresh budgetDescription amount';
      grid-auto-rows: minmax(3.5rem, auto);
      gap: 0.5rem;

      &-name {
        grid-area: budgetName;
      }
      &-description {
        grid-area: budgetDescription;
      }
      &-avatar-pic {
        grid-area: purchaser;
      }
      &-status {
        grid-area: status;
      }
    }
    @include mediaMd {
      .new-list-item {
        /* Set Up Grid */
        grid-template-columns: 2fr 1fr;
        grid-template-areas:
          'budgetName       refresh'
          'budgetName        amount'
          'budgetDescription status';
        gap: 1px;
        grid-auto-rows: minmax(auto, 1.35rem);
      }
    }
  }
}
#transactions-list {
  padding: 0;
  margin: 0 0 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @include checkListType(transactions);
}
#budget-transactions-list {
  padding: 0;
  margin: 0 0 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @include checkListType(budgetTransactions);
}
#budgets-list {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @include checkListType(budgets);
}

.new-list {
  &-people {
    display: grid;
    grid-template-areas:
      'ownersLabel  approversLabel  membersLabel'
      'owners       approvers       members';
    grid-template-columns: auto auto auto;

    column-gap: 2em;
    row-gap: 0.5em;
    margin: 0 3em;

    @include mediaMd {
      grid-template-areas:
        'ownersLabel     owners'
        'approversLabel  approvers'
        'membersLabel    members';
      grid-template-columns: auto auto;

      column-gap: 0.5em;
      row-gap: 2em;
      margin: 0.5em;
      margin-left: 0.75em;

      justify-content: flex-start;
    }

    &-owners {
      grid-area: owners;

      display: flex;
      flex-direction: column;

      gap: 0.5em;

      > ul {
        list-style-type: none;
      }
      &-label {
        grid-area: ownersLabel;

        @include mediaMd {
          text-align: right;
        }
      }
    }
    &-approvers {
      grid-area: approvers;

      display: flex;
      flex-direction: column;

      gap: 0.5em;
      > ul {
        list-style-type: none;
      }
      &-label {
        grid-area: approversLabel;

        @include mediaMd {
          text-align: right;
        }
      }
    }
    &-members {
      grid-area: members;

      display: flex;
      flex-direction: column;

      gap: 0.5em;

      &-label {
        grid-area: membersLabel;

        @include mediaMd {
          text-align: right;
        }
      }
    }

    &-title {
      // font-size: 1.5em;
      // font-weight: bold;
      // border-bottom: 2px solid black;
      display: flex;
      flex-direction: row;

      align-items: center;

      gap: 0.25em;
      padding: 0.25em 0 0 0;

      @include mediaMd {
        justify-content: flex-end;
      }
    }
    &-item {
      &-container {
        display: flex;
        flex-direction: row;

        gap: 0.15em;

        &-avatar {
          width: 35px;
          height: 35px;

          justify-self: flex-end;
          align-self: center;

          border-radius: 50%;
          border: 2px solid #dfe0eb;
          margin-left: 10px;
        }
        &-context {
          justify-self: flex-end;
          align-self: flex-end;

          padding: 0.25em 0.35em 0.45em 0.35em;

          font-size: 0.85em;

          border-bottom: 1px solid #dcdcdc;
          &-null {
            justify-self: flex-end;
            align-self: flex-end;

            padding: 0.35em 0.35em 0 0.75em;

            font-size: 0.85em;
            color: #babdbe;
          }
        }
      }
    }
  }
}

// Component Styling
.new-page-list {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  & p {
    color: #637381;
    font-size: 0.75rem;
  }
  &-heading {
    padding: 5px 0 5px 40px;
  }
  &-title {
    width: 100%;
    min-height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 0.7rem 0 0.25rem;
    margin: 0.5rem 0 0 0;
    border-bottom: 2px solid #d8d9dd;

    font-size: 1rem;
    font-family: 'Muli', sans-serif;
    font-weight: 400;

    > h3 {
      margin: 0.2rem;
      padding: 0 0 0.25rem 0.25rem;

      font-size: 1.1rem;
      text-align: left;
      place-self: flex-end;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1.35rem;
      max-height: 2.7rem;
      -webkit-line-clamp: 2; /* Write the number of
                                lines you want to be
                                displayed */
      -webkit-box-orient: vertical;
    }
    > p {
      margin: 0.2rem;
      padding-bottom: 0.25rem;

      text-align: left;
    }
  }
}

.new-list-item {
  width: 100%;

  font-size: 1rem;
  align-items: center;
  padding: 0rem 1rem;
  // margin: 0 0.5rem;

  border-bottom: 1px solid #d8d9dd;

  font-family: 'Muli', sans-serif;
  font-weight: 400;
  justify-content: space-between;
  // border-radius: 0.25rem;
  // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);

  &.transaction-approval {
    background-color: lighten($warning-color, 43%);
    border: 1px solid $warning-color;
    border-radius: 5px;
    &:hover {
      background-color: lighten($warning-color, 33%);
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  &-container-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &-container-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &-avatar-pic {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  &-container-row-purchaser {
    grid-area: purchaser;
    // margin: 0 0.5rem;
  }
  &-container-row-amount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    > p {
      font-size: 0.55rem;
    }
  }
  &-purchaser {
    align-items: center;
    display: flex;
    font-size: 0.75rem;
  }
  &-name {
    font-size: 1rem;

    align-self: center;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.5rem;
    max-height: 3rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;

    @include mediaMd {
      margin: 3px 3px 3px 0;
      // padding: 3px 0 6px 0;
      align-self: flex-start;
      // -webkit-line-clamp: 1;
    }
  }
  &-container-row-action {
    grid-area: status;

    display: flex;

    justify-content: center;
  }
  &-status {
    justify-content: center;
    text-align: center;

    font-size: 0.85rem;
    font-weight: bold;

    // overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 2rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;

    @include mediaMd {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;

      font-size: 0.75rem;

      align-self: flex-end;
      text-align: right;
    }
  }
  &-action {
    justify-content: center;
    text-align: center;

    // margin: 0 0.5rem;
    font-size: 0.85rem;
    font-weight: bold;
    color: $flame;

    @include mediaMd {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;

      font-size: 0.75rem;
      margin-left: 0;
      justify-content: flex-end;
      text-align: right;
    }
  }
  &-refresh {
    grid-area: refresh;

    justify-content: center;
    text-align: left;

    font-size: 0.85rem;
    font-weight: bold;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
    max-height: 2rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;

    @include mediaMd {
      font-size: 0.75rem;
      line-height: 0.85rem;
      max-height: 1.7rem;

      text-align: right;
      align-self: flex-start;

      -webkit-line-clamp: 1;
    }
  }
  &-date {
    grid-area: date;

    // margin: 0 0.5rem;

    font-size: 0.75rem;
    color: #637381;
    text-align: right;
    white-space: nowrap;
    @include mediaMd {
      align-self: center;
    }
  }
  &-amount {
    grid-area: amount;

    font-weight: bold;
    white-space: nowrap;

    align-content: center;
    align-self: center;
    justify-self: flex-end;

    @include mediaMd {
      // padding: 0;
      // margin: 0.5em 0 1em 0;
    }
  }

  &-role {
    font-weight: bold;
  }
  &-description {
    font-size: 0.75rem;
    color: #637381;

    padding: 0 1rem 0 2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 3rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
    @include mediaMd {
      align-self: flex-end;
      padding: 0;
    }
  }
  &-transactionDescription {
    font-size: 0.75rem;
    color: #9fa2b4;
    padding: 0 0.5rem 0 2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 3rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
    @include mediaMd {
      padding: 0;
      margin: 3px 3px 3px 0;
      -webkit-line-clamp: 1;
    }
  }
  &-costCode {
    font-size: 0.75rem;
    color: #9fa2b4;
    padding: 0 0.5rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 3rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
    @include mediaMd {
      justify-content: flex-end;
      text-align: right;
      padding: 0;
      margin: 3px 0px 3px 0;
      -webkit-line-clamp: 1;
    }
  }
  &-email {
    font-size: 12px;
    color: #637381;
    flex: 0 0 40%;
    height: 15px;
    overflow: hidden;
  }

  &-type {
    font-size: 0.9rem;
    color: #637381;
  }
  &-delete {
    font-size: 0.9rem;
    color: #637381;
    text-align: right;
  }
  // Budget List
  &-nameBudget {
    overflow: hidden;
    text-align: center;
  }
  &-namebudgettran {
    flex: 0 0 25%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
  }
}
.role-list-item {
  display: block;
  padding: 10px;
}

@include mediaMd {
  .new-page-list {
    &-title {
      margin-bottom: 0rem;
    }
  }
  .new-list-item {
    padding: 0.625rem 0.5rem 0.625rem 0.5rem;
    margin: 0 0.25rem 0 0.25rem;
    &-amountRem {
      display: none;
    }
    &-avatar-pic {
      border: 1px solid #dfe0eb;
      width: 25px;
      height: 25px;
    }

    &-description {
      overflow: hidden;
      margin: 0;
      -webkit-line-clamp: 1;
    }
    &-action {
    }
    &-container-row-amount {
      > p {
        display: none;
      }
      > .success {
        display: none;
      }
    }
    &-container-row-action {
      justify-content: flex-end;
      align-self: flex-end;
    }
    &-amount {
      place-items: flex-end;
    }
  }
  .new-page-list {
    padding: 0;
    &-title {
      border-bottom: none;
      flex-wrap: wrap;
    }
  }
}
