// Ease Out
@mixin easeOut {
  transition: all 0.5s ease-out;
}

@mixin fastOut {
  transition: all 300ms ease-out;
}

// Media Query Mixins
@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}

// Another Option for Media Queries
@mixin responsiveMin($size) {
  @media (min-width: + $size) {
    @content;
  }
}
@mixin responsiveMax($size) {
  @media (max-width: + $size) {
    @content;
  }
}

@mixin responsiveMinMax($minWidth, $maxWidth) {
  @media screen and (min-width: + $minWidth) and (max-width: + $maxWidth) {
    @content;
  }
}

// Input Labels
@mixin label-inactive {
  font-size: 1.2rem;
  transform: translateY(0);
}

@mixin label-active {
  font-size: 0.75rem;
  transform: translateY(-14px);
}

// Browser Adaption
@mixin transform-y($value) {
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  -moz-transform: translateY($value);
  -o-transform: translateY($value);
  transform: translateY($value);
}

// Set Text Color
// @function set-text-color($color) {
//   @if (lightness($color) > 40) {
//     @return black;
//   } @else {
//     @return white;
//   }
// }
