// Color Variables
$primary-color: #282828;
$secondary-color: #52d9ff;
$primary-color--dark: white;
$secondary-color--dark: #52d9ff;

$success-color: #29cc97;
$danger-color: #fd4d4d;
$warning-color: #fec810;

$btn-p--light: rgba(54, 55, 64, 0.5);
$btn-p--dark: #dfe0eb;

$light-grey-color: #dfe0eb;
$med-grey-color: #637381;
$nav-bg--light: #fbfafa;
$nav-bg--dark: #282828;
$line-bg--light: #282828;
$line-bg--dark: #fff;

$bg--light: white;
$bg--dark: $primary-color;

$box-shadow--light: 0 0 4px hsla(0, 0%, 75.7%, 0.35);
$box-shadow--dark: 0 0 4px hsla(0, 0%, 75.7%, 0.35);

$btn-box-shadow--light: -2px 0px 4px rgba(0, 0, 0, 0.25);
$btn-box-shadow--dark: -2px 0px 4px #dfe0eb;

$greeting-text--light: #000;
$greeting-text--dark: white;

$greeting-text-small--light: #637381;
$greeting-text-small--dark: #637381;

// Transitions
$fast-animation-duration: 300ms;
$animation-duration: 0.4s;

// Screen Size Breakpoints (width)
$iPhone5: 320px;
$GalaxyS5: 360px;
$iPhone678: 375px;
$iPhoneX: 375px;
$Pixel2: 411px;
$iPhone678Plus: 414px;
$iPad: 768px;
$iPadPro: 1024px;
$desktopMin: 769px;
$desktopMax: 1025px;
$Xldesktop: 1171px;
$XXldesktop: 1400px;
$XXXldesktop: 1550px;

//Mac OS X Colors

$os11-system-blue-aqua: #007bff;
$os11-system-blue-dark: #0a84ff;
$os11-system-brown-aqua: #a2855e;
$os11-system-brown-dark: #ac8f68;
$os11-system-red-aqua: #ff3a30;
$os11-system-red-dark: #ff443a;
$os11-system-purple-aqua: #af52de;
$os11-system-purple-dark: #bf5af2;

// Vendrix Blue from Landing Page
$vendrix-blue-50: #f4fdff;
$vendrix-blue-100: #e8faff;
$vendrix-blue-200: #c6f3ff;
$vendrix-blue-300: #a4ecff;
$vendrix-blue-400: #60ddff;
$vendrix-blue-500: #1ccfff;
$vendrix-blue-600: #19bae6;
$vendrix-blue-700: #0173bc;
$vendrix-blue-800: #0d5d73;
$vendrix-blue-900: #083e4d;

:export {
  primaryColor: $primary-color;
}
