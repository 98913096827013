.form-field {
  display: block;
  margin: 3vh 0;
  width: 100%;
  @include responsiveMin($desktopMin) {
    width: 80%;
  }
  &-small {
    display: block;
    margin: 0 0 5px 0;

    text-align: left;
    width: 100%;
    @include responsiveMin($desktopMin) {
      width: 80%;
    }
  }

  &-select {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0;
    padding: 6px 0;
    position: relative;
    transition: all $animation-duration;
    width: 100%;
    &-label {
      min-width: fit-content;
      display: flex;
      font-size: 1.2rem;
      padding: 6px 12px 6px 0;
      text-align: left;
    }
  }

  &__switch {
    text-align: center;
    margin: 0;
    padding: 2vw;

    & p {
      padding: 2vh;
    }
  }

  &__select {
    margin-top: 10px;
    &-small {
      margin-top: 20px;
    }
  }
}
.auto-approve-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  align-content: center;
  text-align: center;
  padding: 2vh 0;
  color: $success-color;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: lighten($success-color, 20);
  }
}

.delegate-purchase-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: left;
  align-items: center;
  text-align: left;
  padding: 2vh 0;
  color: $vendrix-blue-700;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: lighten($vendrix-blue-700, 20);
  }
}
.delegate-purchase-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: right;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding: 1vh 0;
  color: $danger-color;
  font-weight: 700;
  cursor: pointer;
}

.delegated-summary {
  // color: $os11-system-purple-aqua;
  color: $vendrix-blue-700;
  font-weight: bold;
}

.new-feature {
  color: $warning-color;
  font-size: 14px;
  padding-left: 5px;
  text-align: center;
}
.form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;
  width: 80%;
  &-item {
    width: 100%;
  }
  // &-responsive {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: left;
  //   margin: 6px 0 12px;
  // }
  // & p {
  //   min-width: 55%;
  //   max-width: 55%;
  //   font-size: 14px;
  //   padding: 0 0 0 16px;
  // }
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    //   &-responsive {
    //     display: flex;
    //     flex-direction: row;
    //     align-content: center;
    //     align-items: center;

    // }
  }
}
