.section-title {
  font-family: 'Muli', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 15px 23px 5px 43px;
  @include themed() {
    color: t($primary-color);
  }
  &-detail {
    font-weight: 700;
    padding: 15px 23px 5px 43px;
    font-size: 12px;
    position: relative;
    margin: 20px 45% 20px 0;
    text-align: left;
  }
  & span {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.section-bar {
  border-bottom: 1px solid $primary-color;
  bottom: 0;
  // content: "";
  display: block;
  left: 0;
  margin-top: 1vh;
  // position: absolute;
  right: 0;
  // transform: scaleX(30);
  width: 25%;
  &-detail {
    border: 1px solid #d8d9dd;
    width: 100%;
    height: 0;
    margin: 0;
  }
  &-list {
    border: 1px solid #d8d9dd;
    width: 100%;
    height: 0;
    margin: 0 0 1vh 0;
  }
}
@include mediaMd {
  .section-title {
    padding: 15px 10px 15px 30px;
  }
  .section-bar-detail {
    width: 100%;
  }
  .transaction-section-title {
    padding: 15px 10px 15px 30px;
  }
}
// @include responsiveMax($desktopMax) {
//   .transaction-section-title {
//     padding: 15px 10px 15px 30px;
//   }
// }

.page-list-section-title {
  font-family: 'Muli', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  // margin-right: 10%;
  padding: 15px 23px 5px 43px;
  @include themed() {
    color: t($primary-color);
  }
  &-detail {
    font-weight: 700;
    padding: 15px 23px 5px 43px;
    font-size: 12px;
    position: relative;
    margin: 20px 45% 20px 0;
    text-align: left;
  }
  & span {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
