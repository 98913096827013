/* Add a black background color to the top navigation */
.titlebar {
  @include themed() {
    background-color: t($nav-bg);
  }

  // height: 51px;
  overflow: hidden;
  border-bottom: 1px solid #d8d9dd;

  &-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Style the links inside the navigation bar */
  &-list-title {
    float: left;
    color: #363740;
    text-align: center;
    padding: 14px 24px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    // opacity: 80%;
    &:hover {
      background-color: #ddd;
      color: black;
    }
    &.active {
      font-weight: 700;
      border-bottom: 2px solid #5c5d60;
      color: darken(#363740, 10);
      // opacity: 100%;
    }
  }
}

// /* Change the color of links on hover */
// .titlebar a:hover {
//   background-color: #ddd;
//   color: black;
// }

// /* Add a color to the active/current link */
// .titlebar a.active {
//   font-weight: 700;
//   border-bottom: 2px solid #5c5d60;
//   color: darken(#363740, 10);
//   // opacity: 100%;
// }

@include mediaMd {
  .titlebar {
    display: none;
  }
}
