// Form - Create Purchase/Budget
.form {
  &-main {
    display: flex;
    flex-direction: column;
  }

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;

    h2 {
      padding: 0;
      font-weight: 700;
      font-size: 1.3rem;
    }
  }

  &-x-btn {
    position: relative;
    padding-right: 20px;
    &.close {
      transform: rotate(180deg);
      @include easeOut;
    }

    &-line {
      width: 23px;
      height: 1px;
      // margin: 0 0 5px 0;
      @include themed() {
        background-color: t($line-bg);
      }

      &:nth-child(1) {
        transform: rotate(45deg) translate(0.5px, 0.5px);
      }

      &:nth-child(2) {
        transform: rotate(-45deg) translate(0.5px, -0.5px);
      }
    }
  }

  // Form navigation and submit buttons at bottom
  &-nav-bottom {
    // display: flex;
    // flex-direction: row;
    width: 50%;
    text-align: center;
    margin: 2vh 0;
    // padding: 0 10vw;
  }
  &-submit-btn {
    // display: flex;
    // flex-direction: row;
    width: 50%;
    text-align: center;
    margin-top: 3vh;
  }
  &-continue-btn {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 10vw;
  }
  &-responsive-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }
}
.form-select {
  width: 100%;
  // margin-left: 15%;
  // margin-top: 24px;
  padding: 0;
  font-size: 0.875rem;
  &-small {
    width: 100%;
    // margin-left: 15%;
    margin-top: 5px;
  }
  @include mediaMd {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
    padding: 0;
    &-small {
      width: 100%;
      // margin-left: 15%;
      margin-top: 5px;
    }
  }
}
