.detail {
  &-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 0 20px 0 0;
    & h2 {
      padding: 0;
      font-weight: 700;
      font-size: 1.3rem;
    }
  }
  //   &-page-totals {
  //     padding: 20px;
  //     margin: 0 20px;
  //     border: 1px solid #dcdcdc;
  //     border-radius: 5px;
  //   }
  &-name-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    margin: 10px;
    &-img {
      border-radius: 50%;
      border: 2px solid #dfe0eb;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    & h4 {
      color: #282828;
      // opacity: 60%;
      font-size: 18px;
      font-weight: 400;
    }
    & p {
      padding-left: 60px;
    }
    & button {
      margin-left: 60px;
    }
  }
  &-input {
    border: 1px solid #999;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin: 2vh 0 0.5vh 0;
    outline: 0;
    padding: 5px 12px 5px 16px;
    width: 100%;
    overflow: hidden;
    // transform: translateY(5vh);
  }
  &-label {
    font-size: 14px;
    color: #637381;
    text-align: right;
    width: 200px;
    &-top {
      text-align: right;
      //   width: 143px;
      width: fit-content;
      &-large {
        font-size: 20px;
      }
    }
    &-wide {
      width: fit-content !important;
    }
    &-role {
      @include responsiveMin($desktopMin) {
        width: 300px !important;
      }
    }
  }
  &-entry {
    font-size: 14px;
    color: $primary-color;
    &-top {
      font-size: 22px;
      padding: 0 10px;
      &-large {
        font-size: 28px;
        padding: 0 20px;
      }
    }
  }
  &-text {
    margin: 0;
  }
  &-nav-btn {
    text-align: center;
  }
  &-googlemap {
    height: 30vh;
    width: 60vw;
    margin: 1em 0;
    border-radius: 0.5em;
  }
  &-fileupload {
    width: 60vw;
    text-align: center;
    &-btn {
      margin: 1vh 0 2vh;
    }
  }
  &-back-icon {
    margin: 0 8px 0 0;
  }
}
.receipt-img {
  width: auto;
  max-width: 90vw;
  max-height: 40vh;

  cursor: pointer;

  @include mediaMd {
    border-radius: 2%;
    border: 2px solid #dfe0eb;
    margin: 0;
  }
}

.detail-x-btn {
  position: relative;
  padding-right: 20px;
  &.close {
    transform: rotate(180deg);
    @include easeOut;
  }

  &-line {
    width: 23px;
    height: 1px;
    // margin: 0 0 5px 0;
    @include themed() {
      background-color: t($line-bg);
    }

    &:nth-child(1) {
      transform: rotate(45deg) translate(0.5px, 0.5px);
    }

    &:nth-child(2) {
      transform: rotate(-45deg) translate(0.5px, -0.5px);
    }
  }
}

// Mobile
@include mediaMd {
  //Detail Elements
  .detail {
    &-page-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 0 70px 0 0;
      & h2 {
        padding: 0;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
    &-name-top {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }

    &-input {
      border: 1px solid #999;
      border-radius: 5px;
      color: #333;
      display: block;
      font-size: 1.2rem;
      margin: 2vh 0 0.5vh 0;
      outline: 0;
      padding: 5px 12px 5px 16px;
      width: 100%;
      overflow: hidden;
      // transform: translateY(5vh);
    }
    &-label {
      font-size: 14px;
      color: #637381;
      width: 120px;

      &-top {
        text-align: left;
        width: 143px;
        // width: fit-content;
      }
    }
    &-entry {
      font-size: 14px;
      color: $primary-color;

      &-top {
        font-size: 22px;
        padding: 0;
        width: 75px;
        text-align: right;
      }
    }
    &-text {
      margin: 0;
    }
    &-nav-btn {
      margin: 30px 0;
      text-align: center;
    }
    &-googlemap {
      height: 30vh;
      width: 100%;
      margin: 1vh 0;
      border-radius: 0;
    }
    &-fileupload {
      width: 80vw;
      text-align: center;
      &-btn {
        margin: 1vh 0 2vh;
      }
    }
  }

  .detail-x-btn {
    position: relative;
  }
}

.detail-page-title h2 {
  @include responsiveMax($iPhone5) {
    font-size: 1.1rem;
  }
}

@include responsiveMax($iPad) {
  // .detail-label-top {
  //   text-align: left;
  //   width: 250px;
  // }
  // &-googlemap {
  //   height: 30vh;
  //   width: 100%;
  // }
  // &-fileupload {
  //   width: 60vw;
  // }
}
