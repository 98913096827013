// Card List Item
.cardpage-list-item {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }

  &-cardnumber {
    flex: 0 0 25%;
    padding: 10px 10px 10px 10px;
    overflow-x: hidden;
  }
  &-cardimage {
    flex: 0 0 8%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
    align-items: center;
  }
  &-cardholdername {
    flex: 0 0 25%;
    padding: 10px 0 10px 10px;
    overflow-x: visible;
  }
  &-purchaselimit {
    color: #282828;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    padding: 10px 0 10px 10px;
  }
  &-cardstatus {
    color: #29cc97;
    flex: 0 0 15%;
    padding: 0 10px;
    /* font-weight: bold; */
    &-approval {
      color: $warning-color;
      flex: 0 0 5%;
      padding: 5px;
    }
    &-active {
      color: #29cc97;
      flex: 0 0 5%;
      // padding: 5px;
    }
    &-inactive {
      color: #fd4d4d;
      flex: 0 0 5%;
      // padding: 5px;
      /* font-weight: bold; */
    }
  }
  &-autobudgets {
    flex: 0 0 20%;
    padding: 0 10px;
    font-weight: 600;
  }
  &-plannedpurchases {
    flex: 0 0 20%;
    padding: 0 10px;
    font-weight: 600;
  }
  &-holdbtn {
    flex: 0 0 15%;
  }
  &-unholdbtn {
    flex: 0 0 17%;
  }
  &-deletebtn {
    flex: 0 0 10%;
  }
  &-btngroup {
    flex: 0 0 25%;
  }
}
.card-list {
  margin-top: 15px;
}

.trash-icon {
  padding: 1px;
}

@include mediaMd {
  /* Designate Grid Items */
  .cardpage-list-item {
    &-cardholdername {
      grid-area: cardholdername;
      padding: 0 10px 0 0;
      font-size: 18px;
      overflow-x: hidden;
      overflow-y: none;
      word-wrap: break-word;
      // width: 250px;
      height: 25px;
      // word-break: break-all;
      //   min-width: 250px;
      //   max-height: 25px;
    }
    &-purchaselimit {
      color: #282828;
      font-size: 18px;
      font-weight: 600;
      text-align: right;
    }
    &-cardnumber {
      // grid-area: cardnumber;
      color: gray;
      padding: 0 5px 0 10px;
      font-size: 14px;
      overflow: hidden;
      min-width: 100%;
      max-height: 25px;
    }
    &-cardstatus {
      grid-area: cardstatus;
      padding: 0;
      font-size: 16px;
      text-align: left;
      overflow: hidden;
      max-width: 20px;
    }

    &-autobudgets {
      padding: 0;
      grid-area: autobudgets;
      text-align: left;
      font-size: 16px;
      // color: #59a1c9;
    }
    &-plannedpurchases {
      padding: 0;
      grid-area: plannedpurchases;
      text-align: left;
      font-size: 16px;
      // color: #59a1c9;
    }
    &-holdbtn {
      grid-area: holdbtn;
      padding: 10px 0 0 0;
      font-size: 12px;
      overflow: hidden;
      & span {
        display: none;
      }
    }
    &-unholdbtn {
      grid-area: unholdbtn;
      padding: 10px 0 0 0;
      font-size: 12px;
      overflow: hidden;
      & span {
        display: none;
      }
    }
    &-deletebtn {
      grid-area: deletebtn;
      padding: 10px 0 0 0;
      // text-align: center;
      // font-size: 14px;
      // height: 5px;
      // width: 5px;
    }
    &-cardimage {
      display: none;
    }
    &-drop {
      grid-area: drop;
      text-align: right;
    }

    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      'cardholdername cardholdername'
      'autobudgets   drop'
      'plannedpurchases plannedpurchases';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
}
@include responsiveMax($iPhone5) {
  .cardpage-list-item {
    min-width: 320px;
  }
}
