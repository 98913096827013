.form-modal-content {
  margin: 10% auto;
  border-radius: 5px;
  //mdl shadow
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  //mdl card
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  max-width: 500px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  @include mediaSm {
    max-width: 330px;
  }
}

.mdl_card-title-form {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  // padding: 16px;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.form-modal-title {
  display: flex;
  justify-content: start;
  align-self: start;
  margin: 0 0 10px 8%;
  //   flex-direction: row;
  //   align-items: center;
  //   text-align: center;
  //   flex-wrap: wrap;
  @include responsiveMin($desktopMin) {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // text-align: center;
    // margin: 0 20px 0 0;
  }
}

.form-modal-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 90%;
  text-align: left;
  align-items: flex-start;

  &-item {
    // display: flex;
    // flex-direction: column;
    margin: 0;
    margin-top: 5vh;
    text-align: left;
  }
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 6px 0 12px;
    &-responsive {
      display: flex;
      flex-direction: column;
      align-items: left;
      margin: 6px 0 12px;
    }
    & p {
      min-width: 55%;
      max-width: 55%;
      font-size: 14px;
      padding: 0 0 0 16px;
    }
  }
  @include responsiveMin($desktopMin) {
    margin: 0 0 0 8%;
    // width: 100%;
    &-item {
      display: flex;
      flex-direction: column;
      margin-top: 5vh;
    }
    &-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      &-responsive {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }
      & p {
        min-width: 70%;
      }
    }
  }
}
