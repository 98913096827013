// .page-list {
//   &-item {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 2vh;

//     &-heading {
//       padding: 5px 0 5px 40px;
//     }

//     &-pending {
//       margin-bottom: 0;
//     }
//   }
//   & p {
//     padding: 30px 43px;
//     color: #637381;
//     font-size: 14px;
//   }
// }
.new2-list-item-approval {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  border: 1px solid $warning-color;
  border-radius: 5px;
  // border-bottom: 1px solid $warning-color;
  background-color: lighten($warning-color, 43%);
  gap: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 5px;
  &:hover {
    background-color: lighten($warning-color, 33%);
  }
}
.new2-list-item {
  &-approval {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    border-bottom: 1px solid #d8d9dd;
    align-items: center;
    // padding: 0 0.7rem;
    // margin: 0 0.5rem;

    font-size: 1rem;
    text-decoration: none;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
  }

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  &-avatar-pic {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  &-purchaser {
    display: flex;
    flex: 0 0 15%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
    align-items: center;
  }

  &-name {
    // flex: 0 0 35%;
    padding: 0 0.25rem 0.25rem 0;
    margin: 0.25rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.35rem;
    max-height: 2.7rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
  }
  &-nameBudget {
    flex: 0 0 30%;
    padding: 10px 0 10px 2%;
    overflow-x: hidden;
  }
  &-namebudgettran {
    flex: 0 0 25%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
  }

  &-status {
    justify-content: center;
    text-align: center;

    // margin: 0.25rem;
    // font-size: 0.8rem;

    // overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 2rem;
    -webkit-line-clamp: 2; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
  }

  &-action {
    padding: 5px 8px;
    margin: 0.25rem;

    flex: 0 0 15%;
    font-weight: bold;
    color: $flame;
    border: 0;
  }

  &-statusBudget {
    padding: 0 1%;
    flex: 0 0 10%;
  }

  &-amount {
    margin: 0.25rem;

    flex: 0 0 15%;
    font-weight: bold;
    text-align: right;
    padding-right: 5%;
  }
  &-amountRem {
    flex: 0 0 15%;
    font-weight: bold;
  }
  &-role {
    flex: 0 0 13%;
    font-weight: bold;
  }

  &-description {
    margin: 0.25rem;

    font-size: 12px;
    color: #637381;
    // flex: 0 0 25%;
    height: 15px;
    overflow: hidden;
  }
  &-email {
    font-size: 12px;
    color: #637381;
    flex: 0 0 40%;
    height: 15px;
    overflow: hidden;
  }

  &-date {
    margin: 0.25rem;

    font-size: 0.9rem;
    color: #637381;
    flex: 0 0 20%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 2rem;
    -webkit-line-clamp: 1; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
  }

  &-type {
    font-size: 14px;
    color: #637381;
    flex: 0 0 10%;
  }
  &-delete {
    font-size: 14px;
    color: #637381;
    text-align: right;
    flex: 0 100%;
  }
}

.new2-list-item-status-approval {
  color: $warning-color;
  // padding: 0 10px 0 0;
  flex: 0 0 20%;
}

// .approval-circle-img {
//   border-radius: 50%;
//   border: 2px solid #dfe0eb;
//   width: 40px;
//   height: 40px;
//   margin: 0 10px;
// }
// @include mediaMd {
//   /* Designate Grid Items */
//   .list-item-approval {
//     border-top: 1px solid $warning-color;
//     border-bottom: 1px solid $warning-color;
//     border-radius: 0;
//     border-left: none;
//     border-right: none;
//     background-color: lighten($warning-color, 43%);
//     margin-bottom: 5px;
//     /* Set Up Grid */
//     display: grid;
//     grid-template-areas:
//       'name name amount'
//       'description status date';
//     grid-gap: 1px;
//     padding: 5px 19px 5px 23px;
//     min-width: 375px;
//   }
//   .list-item-status-approval {
//     grid-area: status;
//     padding: 0 5px 0 0;
//     font-size: 16px;
//     text-align: right;
//     justify-self: end;
//     overflow: hidden;
//     max-width: 150px;
//     max-height: 20px;
//   }
// }
// @include responsiveMax($iPhone5) {
//   .list-item-approval {
//     min-width: 320px;
//   }
// }

// @include mediaMd {
//   /* Designate Grid Items */
//   .list-item {
//     &-name {
//       grid-area: name;
//       padding: 0 10px 0 0;
//       font-size: 18px;
//       overflow: hidden;
//       min-width: 250px;
//       max-height: 25px;
//     }
//     &-nameBudget {
//       grid-area: name;
//       padding: 0 10px 0 0;
//       font-size: 18px;
//       overflow: hidden;
//       min-width: 250px;
//       max-height: 25px;
//     }
//     &-status {
//       grid-area: status;
//       padding: 0;
//       font-size: 14px;
//       text-align: right;
//       justify-self: end;
//       overflow: hidden;
//       max-width: 150px;
//       max-height: 20px;
//     }
//     &-statusBudget {
//       grid-area: status;
//       padding: 0 5px 0 0;
//       font-size: 16px;
//       text-align: right;
//       justify-self: end;
//       overflow: hidden;
//       max-width: 150px;
//       max-height: 20px;
//     }
//     &-action {
//       grid-area: status;
//       padding: 0;
//       font-size: 14px;
//       overflow: hidden;
//       justify-self: end;
//       padding: 0;
//       flex: 0 0 15%;
//       font-weight: bold;
//       text-align: right;
//       color: $flame;
//       border: 0;
//     }
//     &-amount {
//       grid-area: amount;
//       text-align: right;
//       font-size: 18px;
//       padding: 0;
//     }
//     &-amountRem {
//       display: none;
//     }
//     &-description {
//       grid-area: description;
//       font-size: 12px;
//       overflow: hidden;
//     }
//     &-date {
//       grid-area: date;
//       text-align: right;
//       font-size: 14px;
//       padding-bottom: 5px;
//     }

//     /* Set Up Grid */
//     display: grid;
//     grid-template-areas:
//       'date date date'
//       'name name amount'
//       'description status status';
//     grid-gap: 1px;
//     border: none;
//     margin: 0 0 20px 0;
//     padding: 0 19px 0 23px;
//     min-width: 375px;
//   }
// }
// @include responsiveMax($iPhone5) {
//   .list-item {
//     min-width: 320px;
//     &-name {
//       min-width: 195px;
//     }
//   }
// }
// @include responsiveMax($GalaxyS5) {
//   .list-item {
//     min-width: 320px;
//     &-name {
//       min-width: 195px;
//     }
//   }
// }
