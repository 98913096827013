.form-container-singleview {
  display: flex;
  flex-direction: column;
  background-color: #fbfafa;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  box-shadow: none;
  padding: 20px;
  margin-top: 20px;
}

.singleview-label {
  margin: 0 40px 0 0;
  font-size: 18px;
}
// .singleview-input {
//   // margin: 0 40px 0 0;
// }
.singleview-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}
