.nav-layout {
  // height: 100vh;
  z-index: 1;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  // border-right: 1px solid #eaeaea;

  @include themed() {
    background-color: t($nav-bg);
  }

  @include mediaMd {
    position: fixed;
    width: 35%;
    float: left;
    overflow: hidden;
    opacity: 0.97;
    margin: 0;
    padding: 0;
    transform: translate3d(-100%, 0, 0);
    @include fastOut;
    &.show {
      visibility: visible;
      // Slide in from side
      transform: translate3d(0, 0, 0);
      // padding: 30px 20px;
    }
  }

  @include mediaSm {
    z-index: 2;
    width: 45%;
  }

  @include responsiveMax($iPhone5) {
    z-index: 2;
    width: 55%;
  }
}

.nav-background {
  @include themed() {
    background-color: t($nav-bg);
  }

  border-right: 1px solid #eaeaea;
}

.navbar {
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
  justify-content: space-between;

  padding: 40px 30px;
  margin: 0;

  list-style-type: none;

  @include fastOut;

  @include mediaMd {
    height: 100%;

    display: flex;
    flex-direction: column;

    position: fixed;
    justify-content: space-between;

    padding: 40px 0 40px 30px;
    margin: 0;

    list-style-type: none;

    @include fastOut;
  }
}

.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  // flex-direction: row;
  // color: $primary-color;
  color: #6c6c72;
  font-weight: 400;
  font-size: 16px;
  padding: 5px 0;
  margin: 10px 0;
  @include fastOut;

  &-active {
    @include themed() {
      color: t($primary-color);
    }

    font-weight: 700;
    & .nav-icon {
      color: darken(#52d9ff, 10);
    }
  }
}

.nav-link-container {
  width: 120px;
  &:hover {
    .nav-word-container {
      @include themed() {
        color: t($primary-color);
      }
      font-weight: 700;
    }
    & .nav-icon-container {
      color: darken(#52d9ff, 10);
    }
  }
}

.nav-icon {
  margin-right: 10px;
}

.nav-footer {
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 10px 0;
}

.nav-close-btn {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: 50%;
  margin-top: 10px;
  color: #363740;
  opacity: 50%;
  font-weight: 700;
  position: absolute;
  bottom: 1%;
  @include fastOut;
}

.portal-link {
  // @include themed() {
  //   background-image: t($logo);
  //   color: t($primary-color);
  // }
  // background-repeat: no-repeat;
  margin: 9px 10px 0 0;
  height: 18px;
  width: 18px;
}

.nav-modal {
  position: relative;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}
