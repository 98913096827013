.search {
  width: 60%;
  min-width: 250px;
  position: relative;

  display: flex;
  align-items: center;

  border: 1px solid #dfe1e5;
  border-radius: 24px;

  padding: 0.2rem 1rem;
  margin: 0.15rem;
  margin-bottom: 0.4rem;

  text-overflow: ellipsis;

  @include responsiveMax($iPad) {
    min-width: none;
    // margin: 0;
    width: 100%;
    // width: 50%;
  }
}
.search-button {
  width: 16px;
  height: 16px;
  text-align: right;
  justify-content: right;

  border: none;
  border-radius: 24px;
  margin: 0.2rem;
  margin-right: 0.5rem;
  // padding-right: 13px;

  font-size: 1rem;
  background: #fff;
  // color: rgba(54, 55, 64, 0.5);

  cursor: pointer;
}

.searchTerm {
  // width: 100%;
  padding: 5px;
  height: 34px;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
  outline: none;
  color: #282828;
  border: none;
  border-radius: 24px;
}

.searchTerm:focus {
  color: #000;
}
