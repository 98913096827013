// Form Elements with Animated Floating Labels
.form-field {
  &__control {
    position: relative;
    width: 100%;
    // @include responsiveMin($desktopMin) {
    box-sizing: border-box;
    min-width: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    background-color: rgb(255, 255, 255);
    margin-bottom: 8px;
    //   font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 1.35;
    // color: rgb(26, 26, 26);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(22, 22, 51, 0.145);
    border-image: initial;
    overflow: hidden;
    transition: border-color 0.2s ease 0s;
    // }
  }

  &__label {
    display: block;
    font-size: 1.2rem;
    font-weight: normal;
    left: 0;
    margin: 0;
    padding: 18px 0 0 12px;
    // @include mediaMd {
    //   padding: 18px 0 0 10%;
    // }
    position: absolute;
    top: 0;
    transition: all $animation-duration;
    width: 100%;

    &-select {
      display: block;
      font-size: 1.2rem;
      font-weight: normal;
      margin: 0;
      padding: 0 0 6px 12px;
      position: relative;
      transition: all $animation-duration;
      width: 100%;
      text-align: left;
    }
  }

  &__input {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #999;
    color: #333;
    // display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 5px 0 5px 16px;
    width: 100%;
    overflow: hidden;
    &[disabled] {
      color: #dcdad1;
    }
    &-tip {
      font-size: 12px;
      color: #637381;
      padding: 3px 0 0 10px;
    }
  }

  &__bar {
    border-bottom: 2px solid $primary-color;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all $animation-duration;
    width: 1%;
  }

  &__switch {
    margin-left: 8%;
    margin-top: 24px;
    &-small {
      margin: 10px 0 0 20px;
    }
  }

  &__select {
    margin-top: 10px;
    &-small {
      margin-top: 10px;
    }
  }
}

.form-field__input {
  &:focus,
  &:not(:placeholder-shown) {
    // @include label-inactive;
    & + .form-field__label {
      color: $primary-color;
      @include label-active;
      & + .form-field__bar {
        border-bottom: 2px solid #52edff;
        transform: scaleX(100);
      }
    }
  }
}

.form-field__select {
  &:focus,
  &:not(:placeholder-shown) {
    // @include label-inactive;
    & + .form-field__label {
      color: $primary-color;
      transform: translateY(-40px);
      font-size: 1rem;
      // & + .form-field__bar {
      //   border-bottom: 2px solid $secondary-color;
      //   transform: scaleX(150);
      // }
    }
  }
}
