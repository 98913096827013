@include responsiveMax($iPad) {
  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1.25em 0.25em 0 0.25em;
    overflow: none;
    text-align: left;
    margin-bottom: 0;
  }

  .page-heading {
    display: flex;
    flex-direction: column;
    & h1 {
      @include themed() {
        color: t($primary-color);
      }
      display: block;
      text-align: left;
      font-size: 1.1rem;
      margin-bottom: 1vh;
    }

    &-text {
      font-size: 15px;
      margin-bottom: 1.5vh;
      @include themed() {
        color: t($primary-color);
      }
    }
  }

  .page-actions {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
  }

  .page-list-controls {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }
}

@include responsiveMax($iPhone5) {
  .page-actions {
    padding: 0px 20px;
  }
  .page-container {
    padding-bottom: 40px;
  }
}
@include responsiveMax($GalaxyS5) {
  .page-actions {
    padding: 0px 20px;
  }
  .page-container {
    padding-bottom: 40px;
  }
}
@include responsiveMax($desktopMax) {
  .page-list-title-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    & h1 {
      @include themed() {
        color: t($primary-color);
      }
      display: block;
      text-align: left;
      font-size: 1.1rem;
      margin-bottom: 1vh;
    }

    &-text {
      font-size: 15px;
      margin-bottom: 1.5vh;
      @include themed() {
        color: t($primary-color);
      }
    }
  }
}
