@include mediaMd {
  .menu-btn {
    position: fixed;
    z-index: 3;
    left: 20px;
    bottom: 20px;
    padding: 20px 0 0 15px;
    @include themed() {
      background-color: t($bg);
    }
    border-radius: 50%;
    border: 2px solid darken(white, 10);
    box-shadow: -2px 8px 5px lighten($med-grey-color, 10);
    width: 60px;
    height: 60px;
    cursor: pointer;
    @include fastOut;

    // Rotate Into X With Menu Lines
    &.close {
      transform: translate3d(116px, 0, 0);

      .btn-line {
        // Line 1 - Rotate
        &:nth-child(1) {
          opacity: 0;
        }
        // Line 2 - Hide
        &:nth-child(2) {
          transform: rotate(45deg) translate(7px, 5px);
          width: 20px;
        }
        // Line 3 - Rotate
        &:nth-child(3) {
          transform: rotate(-45deg) translate(10px, -7px);
          width: 20px;
        }
      }
    }
  }

  .btn-line {
    width: 28px;
    height: 2px;
    margin: 0 0 5px 0;
    @include themed() {
      background-color: t($line-bg);
    }

    @include fastOut;

    &:nth-child(1) {
      width: 10px;
    }
    &:nth-child(2) {
      width: 20px;
    }
  }
}

// .menu-button {
//   display: none;
//   @include mediaMd {
//     display: block;
//     margin: 0;
//     padding: 14px;
//     cursor: pointer;
//     font-size: 1.1rem;
//   }
// }
