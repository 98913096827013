.chartDashboard {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.mongo-charts {
  border-bottom: 1px solid #cccc;
  margin: 0;
  padding: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
#numberTransactionsChart {
  height: 150px;
  width: 300px;
}
#accountBalanceChart {
  height: 150px;
  width: 300px;
}
#transactionsByMerchantChart {
  height: 300px;
  width: 300px;
}
#remainingBudgetChart {
  height: 300px;
  width: 300px;
}
#customerLocationChart {
  height: 300px;
  width: 300px;
}
#amountByMerchantChart {
  height: 400px;
  width: 300px;
}
#spendByUserChart {
  height: 400px;
  width: 300px;
}

@include responsiveMin(991px) {
  /* Designate Grid Items */
  #numberTransactionsChart {
    height: 150px;
    width: 300px;
  }
  .numberCardTransactions {
    grid-area: numberCardTransactions;
  }
  #accountBalanceChart {
    height: 150px;
    width: 300px;
  }
  .accountBalance {
    grid-area: accountBalance;
  }
  #transactionsByMerchantChart {
    height: 320px;
    width: 500px;
  }
  .transactionsByMerchant {
    grid-area: transactionsByMerchant;
  }
  #remainingBudgetChart {
    height: 300px;
    width: 480px;
  }
  .remainingBudgetAmount {
    grid-area: remainingBudgetAmount;
  }
  #customerLocationChart {
    height: 400px;
    width: 820px;
  }
  .customerLocations {
    grid-area: customerLocations;
  }
  #amountByMerchantChart {
    width: 480px;
    height: 400px;
  }
  .amountByMerchant {
    grid-area: amountByMerchant;
  }
  #spendByUserChart {
    height: 400px;
    width: 1300px;
  }
  .spendByUser {
    grid-area: spendByUser;
  }

  .mongo-charts {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0.2rem 0.5rem 0 rgba(6, 22, 33, 0.22);
  }
  .chartDashboard {
    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      "numberCardTransactions transactionsByMerchant remainingBudgetAmount "
      "accountBalance transactionsByMerchant remainingBudgetAmount "
      "customerLocations customerLocations amountByMerchant "
      "spendByUser spendByUser spendByUser ";
    grid-gap: 20px;
    border: none;
    margin: 0;
    padding: 0;
    // max-width: 1300px;
    // word-wrap: none;
  }
}
