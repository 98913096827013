.confirmation-modal {
  &-main {
    margin: 10% auto;
    border-radius: 5px;
    //mdl shadow
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    //mdl card
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    min-height: 200px;
    overflow: hidden;
    max-width: 500px;
    z-index: 1;
    position: relative;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    @include mediaSm {
      max-width: 330px;
    }

    &.danger {
      color: #282828;
      border: 1px solid $danger-color;
      border-radius: 5px;
      background-color: lighten($danger-color, 33%);
    }
    &.success {
      color: #282828;
      border: 1px solid $success-color;
      border-radius: 5px;
      background-color: lighten($success-color, 47%);
    }
  }
  &-btn-row {
    // margin: 20px 0;
    padding: 20px 0;
  }
  &-close-btn {
    // font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: none;
    background: none;
    border: none;
    font-size: 16px;
    transform: translateY(0);
    // transition: transform 150ms, box-shadow 150ms;
    text-transform: capitalize;
  }
  &-confirm-btn {
    font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: none;
    background: none;
    border: 0.5px solid lighten($danger-color, 33%);
    font-size: 16px;
    transform: translateY(0);
    // transition: transform 150ms, box-shadow 150ms;
    text-transform: capitalize;
    padding: 10px 20px;
    margin-right: 5px;
    &:hover {
      background-color: lighten($danger-color, 30%);
      // border: 0.5px solid $med-grey-color;
      border-radius: 5px;
    }
  }
  &-cancel-btn {
    // font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: none;
    background: none;
    border: none;
    font-size: 16px;
    transform: translateY(0);
    // transition: transform 150ms, box-shadow 150ms;
    text-transform: capitalize;
    padding: 10px 20px;
    margin-left: 5px;
    &:hover {
      background-color: lighten($danger-color, 30%);
      // border: 0.5px solid $med-grey-color;
      border-radius: 5px;
    }
  }
}

@include mediaMd {
  .confirmation-modal-main {
    top: 50%;
    width: 80%;
    font-size: 20px;
  }
}
