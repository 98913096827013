.banner-container {
  position: sticky;
  top: 0px;
  z-index: 120000;
}
.banner {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 10px;
  width: 100%;
  text-decoration: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 18px;
  font-weight: 500;
  @include responsiveMax($desktopMin) {
  }
}
.success-banner {
  border-top: 1px solid $success-color;
  border-bottom: 1px solid $success-color;
  background-color: lighten($success-color, 50%);
}
.danger-banner {
  border-top: 1px solid $danger-color;
  border-bottom: 1px solid $danger-color;
  background-color: lighten($danger-color, 30%);
}

.pending-approval-banner {
  padding: 10px 0;
  border-top: 1px solid $warning-color;
  border-bottom: 1px solid $warning-color;
  background-color: lighten($warning-color, 43%);
  &:hover {
    background-color: lighten($warning-color, 33%);
  }
}
.error-banner {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $danger-color;
  border-bottom: 1px solid $danger-color;
  background-color: lighten($danger-color, 25);
  // box-shadow: 4px -4px -4px rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: lighten($danger-color, 28);
  }
  &-x-btn {
    display: inline-block;
    background: var(--light-color);
    color: $primary-color;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    outline: none;
    text-decoration: none;
    text-align: center;
    padding: 0.4rem 1.3rem;
  }
}
.banner-row {
  display: flex;
  flex-direction: row;
}

.banner-section-title {
  font-weight: 700;
  text-align: center;
}

.banner-heading-text {
  font-size: 14px;
  color: $med-grey-color;
  padding: 0 40px;
}

.banner-column {
  display: flex;
  flex-direction: column;
}

.banner-link-text {
  font-size: 14px;
  text-align: center;
  padding: 2px 0 10px;
  font-weight: 400;
}

.error {
  font-size: 12px;
  color: var(--red-600);
  margin-top: 0.25rem;
}

@media (prefers-color-scheme: dark) {
  .error {
    color: var(--red-400);
  }
}

@include mediaMd {
  .pending-approval-banner {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10;
    padding: 0 10px;
    width: 100%;
    text-decoration: none;
    border-top: 1px solid $warning-color;
    border-bottom: 1px solid $warning-color;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: lighten($warning-color, 43%);
    //   box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: lighten($warning-color, 33%);
    }
  }

  .banner-section-title {
    font-weight: 700;
    padding: 10px 0 3px;
    font-size: 15px;
    text-align: center;
  }

  .banner-heading-text {
    font-size: 14px;
    color: $med-grey-color;
    padding: 0 40px;
  }

  .banner-row {
    display: flex;
    flex-direction: row;
  }
  .banner-column {
    display: flex;
    flex-direction: column;
  }

  .banner-link-text {
    font-size: 14px;
    text-align: center;
    padding: 2px 0 10px;
    font-weight: 400;
  }
}
