.users-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  &-circle-img {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 34px;
    height: 3px;
    margin-left: 30px;
  }
  &-username {
    flex: 0 0 20%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
  }
  &-userstatus {
    padding: 0 10px 0 0;
    flex: 0 0 20%;
  }
  &-userrolename {
    font-size: 12px;
    color: #637381;
    flex: 0 0 20%;
    height: 15px;
    overflow: hidden;
  }
  &-usertype {
    font-size: 12px;
    color: #637381;
    flex: 0 0 20%;
    height: 15px;
    overflow: hidden;
  }
  &-card {
    font-size: 10px;
    color: #94A6B3;
    flex: 0 0 20%;
  }
}

@include mediaMd {
  /* Designate Grid Items */
  .users-list-item {
    &-circle-img {
      grid-area: img;
      border-radius: 50%;
      border: 2px solid #dfe0eb;
      width: 36px;
      height: 36px;
      margin-left: 0;
    }
    &-username {
      grid-area: username;
      font-size: 16px;
      padding: 0;
      overflow-x: hidden;
    }
    &-userstatus {
      // display: none;
      grid-area: userstatus;
      font-size: 12px;
      overflow: hidden;
    }
    &-userrolename {
      grid-area: userrolename;
      font-size: 12px;
      color: #637381;
      overflow: hidden;
      width: 145px;
    }
    &-usertype {
      grid-area: usertype;
      font-size: 12px;
      color: #637381;
      // overflow: hidden;
      text-align: right;
    }
    &-card {
      grid-area: card;
      color: #637381;
      text-align: right;
    }
    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      'img username userstatus card'
      'img userrolename usertype usertype'; /* Adjusted the row definition */
    grid-template-columns: 45px auto auto auto;
    grid-gap: 1px;
    border-bottom: 1px solid #dfe0eb;
    margin: 0;
    padding: 20px 10px;
    min-width: 325px;
  }
}
