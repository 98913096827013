.portal-company-detail {
  border-top: 1px solid #d8d9dd;
  display: flex;
  flex-direction: column;
}
.portal-company-header {
  padding-left: 40px;
  border-bottom: 1px solid #d8d9dd;
}
.portal-company-detail-title {
  margin: 23px 0 0 0;
}
.portal-company-statsrow {
  display: flex;
  justify-content: space-between;
  line-height: 2.5;
}
.portal-company-detailitem-column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 10px;

  &-label {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
}
.portal-detail-item-row {
  display: flex;
}
.portal-detail-entry {
  font-size: 14px;
  color: $primary-color;
  margin-top: 10px;
  line-height: 1.5;
}
.portal-detail-label {
  font-size: 14px;
  color: #637381;
  margin-top: 10px;
  line-height: 1.5;
}
