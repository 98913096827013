.detail-fileupload {
  width: 100%;
  text-align: center;
}

.detail-googlemap {
  width: 100%;
  max-height: 50vh;
  border-radius: 1em;
}

.detail-nav-btn {
  text-align: center;

  @include mediaMd {
    margin: 30px 0;
  }
}

.detail-section {
  width: 100%;

  padding: 0 20px;
  margin-bottom: 15px;

  text-align: left;
}
