// Text Color Classes
.success {
  color: $success-color;
}
.warning {
  color: $warning-color;
}
.danger {
  color: $danger-color;
}
.light-grey {
  color: $light-grey-color;
}
.med-grey {
  color: $med-grey-color;
}
.middle-grey {
  color: $middle-grey;
}
.grey {
  color: $grey;
}
.flame {
  color: $flame;
}

.vblue {
  color: $electric-blue;
}

.vendrix-blue-700 {
  color: $vendrix-blue-700;
}
.vendrix-blue-800 {
  color: $vendrix-blue-800;
}

// Bars for Text Delineating
.inline-bar {
  display: inline-block;
  border-bottom: 1px solid $electric-blue;
  width: 100%;
  height: 0;
  margin: 0;
}
.divider-bar {
  width: stretch;

  background: white;
  border-bottom: 2px solid $light-grey-color;

  padding: 1rem 0 0 0;

  font-family: 'Muli', sans-serif;
  font-size: 0.7em;
  line-height: 1.5em;
  white-space: nowrap;

  &-actionRequired {
    border-bottom: 2px solid $warning-color;
  }
  &-teamTransactions {
    border-bottom: 2px solid $electric-blue;
  }

  @include mediaMd {
    margin: 0 0.25em;
  }
}

// Hide on mobile view

.hide-mobile {
  @include responsiveMax($desktopMin) {
    display: none !important;
  }
}
// .hide-desktop {
//   @include responsiveMin($desktopMin) {
//     display: none !important;
//   }
// }

.required-action-text {
  color: $flame !important;
  font-weight: bold !important;
}
