// Header
.header {
  z-index: 10;
  position: relative;

  @include themed() {
    background-color: t($bg);
    box-shadow: t($box-shadow);
  }
}

.header-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-left-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 3vw;
}

.header-right-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.vlogo {
  @include themed() {
    background-image: t($logo);
    color: t($primary-color);
  }
  background-repeat: no-repeat;
  margin: auto 0;
  height: 32px;
  width: 125px;
  & span {
    text-align: center;
    padding: 20px 0 0 35px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
  }
}

.private-beta {
  float: right;
  padding-top: 0;
}

header h1 {
  color: #282828;
  font-size: 1.05rem;
  margin: auto 51px;
  @include themed() {
    color: t($greeting-text);
  }
}

header p {
  font-size: 0.725rem;
  font-weight: 200;
  padding-top: 4px;
  color: #637381;
}

.profile-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  text-align: right;
  float: right;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 200;
  color: #637381;
  min-height: 10vh;
  padding: 0 40px;
}

.profile-icon:hover {
  background-color: #f6f6f6;
}

.circle-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.refresh-icon {
  @include themed() {
    color: t($primary-color);
  }

  margin-right: 10px;
  cursor: pointer;
  &:hover {
    color: #52d9ff;
  }
}

.profile-icon span {
  display: none;
}

@include mediaMd {
  // .header {
  //   position: fixed;
  //   width: 100%;
  //   min-height: 10vh;
  //   box-shadow: 0 0 5px 0 #dfe0eb;
  //   z-index: 2;
  // }

  .header-left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-right-content {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }

  // .vlogo {
  //   display: flex;
  //   align-items: center;
  //   margin: 0;
  //   padding-left: 8vw;
  //   // height: 30px;
  //   width: 40vw;
  // }

  .vlogo {
    @include themed() {
      background-image: t($logo);
      color: t($primary-color);
    }
    background-repeat: no-repeat;
    margin: 9px 0 0 8vw;
    height: 32px;
    width: 125px;
    & span {
      text-align: center;
      padding: 25px 0 0 35px;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
    }
  }

  header h1 {
    display: none;
  }

  .profile-icon {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-align: right;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 400;
    color: darken(#637381, 15%);
    padding: 0 20px 0 0;
  }

  .nav-user-role-name {
    display: none;
  }

  .profile-icon span {
    display: none;
  }

  .circle-img {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  // .refresh-icon {
  //   display: none;
  //   // height: 15px;
  //   // margin-right: 5px;
  // }
}
// @include responsiveMax($iPhone5) {
//   .fc-widget-normal {
//     right: 50px !important;
//     bottom: 30px !important;
//   }
// }
