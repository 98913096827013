.budgetdetail-header-title-row {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  // margin: 15px 0 0;
  padding: 20px 0 6px;
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 0;
    padding: 20px 0;
  }
  // Mobile
  @include mediaMd {
    margin-left: 1.5em;
  }
}

.budgetdetail-header-page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin: 0;
  flex-wrap: wrap;
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 0 20px 0 0;
  }
  & h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
    @include responsiveMin($desktopMin) {
      padding: 0;
      font-weight: 700;
      font-size: 1.3rem;
    }
  }
}

.budgetdetail-header-btn-row {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 12px 0 0;
  align-items: center;
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
  }
}

.budgetdetail-totals-row {
  width: 100%;

  padding: 6px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: none;
  flex-wrap: wrap;
  @include responsiveMin($desktopMax) {
    padding: 10px 40px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    border: none;
  }
  // @include responsiveMin($desktopMin) {
  //   padding: 10px 40px 25px;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-around;
  //   width: 80%;
  //   background-color: #fff;
  //   border: none;
  // }
}

.budgetdetail-subtotals {
  &-container {
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: 10px 24px 24px;
    margin-bottom: 4em;

    align-items: center;
    flex-wrap: wrap;

    background-color: #fbfafa;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: none;
    @include responsiveMin($desktopMax) {
      padding: 10px 40px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 80%;
      background-color: #fbfafa;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      box-shadow: none;
    }
    // @include responsiveMin($desktopMin) {
    //   padding: 10px 40px 10px;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   width: 80%;
    //   background-color: #fbfafa;
    //   border: 1px solid #dcdcdc;
    //   border-radius: 4px;
    //   box-shadow: none;
    // }
  }
  &-row {
    padding: 0 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    @include responsiveMin($desktopMax) {
      width: 100%;
      padding: 10px 0;
      flex-wrap: wrap;
    }
    @include responsiveMin($desktopMin) {
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-around;
    }
  }
}

.budget-progress-bar {
  display: flex;
  flex-direction: row;
  background-color: #dcdcdc;
  box-shadow: 0 3px 3px -5px #dcdcdc, 0 2px 5px #dcdcdc;
  border-radius: 6px;
  position: relative;
  margin: 12px 0 6px;
  height: 15px;
  width: 80%;
  overflow: hidden;
  @include responsiveMin($desktopMin) {
    border-radius: 10px;
    height: 20px;
    margin: 16px 0 4px;
  }
  &-awaitingapproval {
    background: linear-gradient(to left, $warning-color, #dcdcdc);
    // box-shadow: 0 3px 3px -5px $warning-color, 0 2px 5px $white;
    border-radius: 10px 0 0 10px;
  }
  &-approved {
    background: linear-gradient(to left, $success-color, $warning-color);
    // box-shadow: 0 3px 3px -5px $success-color, 0 2px 5px $warning-color;
  }
  &-pending {
    background: linear-gradient(to left, $med-grey-color, $success-color);
    // box-shadow: 0 3px 3px -5px $med-grey-color, 0 2px 5px $med-grey-color;
  }
  &-complete {
    background: linear-gradient(to left, #282828, $med-grey-color);
    // box-shadow: 0 3px 3px -5px $primary-color, 0 2px 5px $primary-color;
    border-radius: 0 10px 10px 0;
  }
}

.progress-done {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.budgetdetail-label {
  font-size: 14px;
  color: #637381;
  width: 120px;
  @include responsiveMin($desktopMin) {
    font-size: 14px;
    color: #637381;
    text-align: right;
    width: 200px;
  }
  &-top {
    text-align: left;
    width: 143px;
    // width: fit-content;
    @include responsiveMin($desktopMin) {
      text-align: right;
      //   width: 143px;
      width: fit-content;
    }
    &-large {
      text-align: left;
      width: 143px;
      font-size: 20px;
      @include responsiveMin($desktopMin) {
        font-size: 20px;
        width: auto;
      }
    }
  }
}

.budgetdetail-entry {
  font-size: 14px;
  color: $primary-color;
  @include responsiveMin($desktopMin) {
    font-size: 14px;
    color: $primary-color;
  }
  &-top {
    font-size: 22px;
    padding: 0;
    width: 75px;
    text-align: right;
    @include responsiveMin($desktopMin) {
      width: auto;
      text-align: left;
      font-size: 22px;
      padding: 0 10px;
    }
    &-large {
      font-size: 22px;
      margin: 0 10px;
      width: 75px;
      text-align: right;
      @include responsiveMin($desktopMin) {
        font-size: 28px;
        padding: 0 20px;
      }
    }
  }
}

.budget-detail-top {
  display: flex;
  flex-direction: row;
  padding: 40px 0 20px;
  font-size: 18px;
}
.budget-member-list {
  text-decoration: none;
  list-style: none;
  display: inline;
  overflow-x: scroll;
  white-space: nowrap;
  & li {
    display: inline-block;
    margin: 10px 20px 30px;
  }
}
.member-wrapper {
  display: flex;
  align-items: center;
}
.member-name {
  padding: 0 5px 0 8px;
}

.budget-detail-description {
  font-size: 16px;
  margin: 10px;
}

.budget-detail-amount {
  font-size: 20px;
  font-weight: 700;
  color: green;
  margin: 10px;
}

.budget-detail-x-item {
  margin-left: auto;
}

.budget-detail-x-btn {
  font-size: 18px;
  font-weight: 700;
  color: #637381;
  text-decoration: none;
}

// Mobile
@include mediaMd {
  .budget-detail-top {
    display: flex;
    flex-direction: row;
    padding: 40px 0 20px;
    font-size: 18px;
  }
  .budget-member-list {
    text-decoration: none;
    list-style: none;
    display: inline;
    overflow-x: scroll;
    white-space: nowrap;
    & li {
      display: inline-block;
    }
  }
}
