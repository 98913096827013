.form-main-addform {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10vh;
  @include responsiveMin($desktopMax) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.form-title-row {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 80%;
  // margin: 15px 0 0;
  padding: 20px 0 6px;
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 15px 0 0;
    padding: 20px 0;
  }
}
.form-link-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0;
  padding: 0;
}

.form-title-user {
  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: 0;
    h2 {
      padding: 0;
      font-weight: 700;
      font-size: 1.3rem;
    }
  }
}

.autobudget-cardholder-title {
  padding-top: 3px;
}

.form-submit-btn-user {
  margin: 2vh 0 0;
  padding: 0;

  @include responsiveMin($desktopMin) {
    margin: 0;
  }
}

.form-container-row-addform {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 6px 12px 12px;
  &-responsive {
    display: flex;
    flex-direction: column;
    margin: 6px 12px 12px;
  }
  &-text {
    text-align: left;
    font-size: 1.2rem;
    padding: 16px 0;
  }
  @include responsiveMin($desktopMin) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 90%;
    @include responsiveMin($desktopMax) {
      width: 80%;
    }
    &-responsive {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      width: 90%;
      @include responsiveMin($desktopMax) {
        width: 80%;
      }
    }
    &-text {
      min-width: 65%;
      text-align: left;
      font-size: 1.2rem;
      @include responsiveMin($desktopMax) {
        min-width: 70%;
      }
    }
  }
}

.addform-input-small {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #999;
  // border-radius: 5px;
  color: #333;
  display: block;
  font-size: 0.875rem;
  margin: 0 0 0 20px;
  outline: 0;
  padding: 8px 10px;
  overflow: hidden;
  @include responsiveMinMax($iPhone678Plus, $desktopMin) {
    width: 70%;
  }
}

.addform-maxwidth {
  max-width: 1000px;
}
