.list-item-budgettran {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
  &-avatar-pic {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  &-purchaser {
    display: flex;
    flex: 0 0 15%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
    align-items: center;
  }

  &-name {
    flex: 0 0 40%;
    padding: 10px 0 10px 43px;
    overflow-x: hidden;
  }
  &-namebudgettran {
    flex: 0 0 20%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
  }

  &-status {
    padding: 0 10px;
    flex: 0 0 15%;
  }
  &-action {
    padding: 5px 8px;
    flex: 0 0 15%;
    font-weight: bold;
    color: $flame;
    border: 0;
  }

  &-amount {
    flex: 0 0 15%;
    font-weight: bold;
    text-align: right;
    padding-right: 5%;
  }
  &-role {
    flex: 0 0 13%;
    font-weight: bold;
  }

  &-description {
    font-size: 14px;
    color: #637381;
    flex: 0 0 20%;
    height: 15px;
    overflow: hidden;
  }
  &-email {
    font-size: 12px;
    color: #637381;
    flex: 0 0 40%;
    height: 15px;
    overflow: hidden;
  }

  &-date {
    font-size: 14px;
    color: #637381;
    flex: 0 0 5%;
  }

  &-type {
    font-size: 14px;
    color: #637381;
    flex: 0 0 10%;
  }
  &-delete {
    font-size: 14px;
    color: #637381;
    text-align: right;
    flex: 0 100%;
  }
}

@include mediaMd {
  /* Designate Grid Items */
  .list-item-budgettran {
    &-avatar-pic {
      border-radius: 50%;
      border: 1.5px solid #dfe0eb;
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
    &-purchaser {
      grid-area: purchaser;
      padding: 0 0 5px 5px;
      font-size: 14px;
      //   font-weight: 700;
      overflow: hidden;
      //   min-width: 250px;
      //   max-height: 25px;
    }
    &-namebudgettran {
      grid-area: namebudgettran;
      padding: 0 10px 0 0;
      font-size: 18px;
      overflow: hidden;
      // overflow-y: none;
      word-wrap: break-word;
      width: 250px;
      height: 25px;
      // word-break: break-all;
      //   min-width: 250px;
      //   max-height: 25px;
    }
    &-status {
      grid-area: status;
      padding: 0;
      font-size: 14px;
      text-align: right;
      overflow: hidden;
    }
    &-action {
      grid-area: status;
      padding: 0;
      font-size: 14px;
      text-align: right;
      overflow: hidden;
      justify-self: end;
      padding: 0;
      flex: 0 0 15%;
      font-weight: bold;
      color: $flame;
      border: 0;
      border-radius: 5px;
    }
    &-amount {
      grid-area: amount;
      text-align: right;
      font-size: 18px;
      padding: 0;
    }
    &-description {
      grid-area: description;
      font-size: 14px;
      overflow: hidden;
      width: 100px;
    }
    &-date {
      grid-area: date;
      font-size: 14px;
      text-align: right;
    }

    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      'purchaser purchaser date'
      'namebudgettran namebudgettran amount'
      'description status status ';
    grid-gap: 1px;
    border: none;
    margin: 0 0 25px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
    // word-wrap: none;
  }
}
@include responsiveMax($iPhone5) {
  .list-item-budgettran {
    min-width: 320px;
    &-namebudgettran {
      width: 195px;
    }
    &-description {
      width: 60px;
    }
  }
}
