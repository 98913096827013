// Generic Button Class
.btn {
  display: inline-block;
  background: var(--light-color);
  color: $primary-color;
  margin-right: 0.5rem;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
  outline: none;
  text-decoration: none;
  text-align: center;
  &-block {
    display: block;
    width: 100%;
  }
  &-light {
    background: var(--light-color);
    color: #333;
  }
  &-bold {
    font-weight: 700;
  }
  &-edit {
    margin: 0 0 0 0.5rem;
  }
  // &:disabled,
  // &[disabled] {
  //   border: 1px solid #999999;
  //   background-color: #cccccc;
  //   color: #666666;
  //   cursor: default;
  // }
}

// Create Action Button (Create/Add a Purchase/Budget/User/Role)
.create-btn {
  cursor: pointer;
  height: auto;
  min-width: 300px;
  @include themed() {
    background-color: t($bg);
    color: t($primary-color);
    box-shadow: t($btn-box-shadow);
  }
  // margin: 40px 10px;
  margin: 1rem;
  padding: 1rem 0 1rem 2rem;
  text-decoration: none;
  border: 1px solid #52d9ff;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  letter-spacing: 0.2px;

  & p {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    @include themed() {
      color: t($btn-p);
    }
    text-decoration: none;
  }
  &-icon {
    margin: 0 20px 0 40px;
  }
  &:hover {
    background-color: lighten(#52d9ff, 31);
  }
}
@include mediaMd {
  .create-btn {
    padding: 20px 0 20px 32px;
    margin: 20px 0 20px;

    &-icon {
      margin-right: 20px;
    }
  }
}
// @include responsiveMax($iPhone5) {
//   .create-btn {
//     padding: 20px 0 20px 20px;
//     &-icon {
//       padding: 0;
//       margin: 0 20px 0 0;
//     }
//   }
// }
// @include responsiveMax($GalaxyS5) {
//   .create-btn {
//     padding: 20px 0 20px 20px;
//     &-icon {
//       padding: 0;
//       margin: 0 20px 0 0;
//     }
//   }
// }
// @include responsiveMin($XXldesktop) {
//   .create-btn {
//     margin: 2.5rem 1rem 1.5rem 1rem;
//   }
// }
// @include responsiveMin($XXXldesktop) {
//   .create-btn {
//     margin: 40px 40px;
//   }
// }

// Button for Auto Budgets

.budget-btn {
  cursor: inherit;
  height: auto;
  min-width: 300px;
  @include themed() {
    background-color: t($bg);
    color: t($primary-color);
    box-shadow: t($btn-box-shadow);
  }
  // margin: 40px 10px;
  margin: 1rem;
  padding: 1rem 0;
  text-decoration: none;
  border: 1px solid #29cc97;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;

  & p {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    &:hover {
      color: #282828;
    }
    @include themed() {
      color: t($btn-p);
    }
    text-decoration: none;
  }
  &-icon {
    margin: 0 20px 0 40px;
  }
  // &:hover {
  //   background-color: lighten(#29cc97, 50);
  // }
  &-namerow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    padding-left: 32px;
  }
  &-autobudget-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    text-decoration: none;
    color: #282828;
    letter-spacing: 0.2px;
    margin-top: 10px;
    padding: 10px 15px 10px 32px;
    border-radius: 10px;
    &-displayname {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }
    &-budgetname {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: #3BACDB;
      text-decoration: none;
      max-width: 140px;
      max-height: 24px;
      overflow: hidden;
      white-space: nowrap; /* Don't forget this one */
      text-overflow: ellipsis;
      scroll-behavior: none;
    }
    &-amountremaining {
      color: #29cc97;
      font-weight: bold;
      padding-right: 20px;
      text-align: right;
    }
    &:hover {
      background-color: lighten(#29cc97, 48);
    }
  }
}
.no-autobudgets {
  font-size: 18px;
}
.autobudget-btn-p {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  padding-left: 32px;
  @include themed() {
    color: t($btn-p);
  }
  text-decoration: none;
}

@include mediaMd {
  .budget-btn {
    padding: 23px 0 20px;
    margin: 20px 0 20px;
    &-autobudget-item {
      padding: 10px 0 10px 32px;
    }
    &-icon {
      margin-right: 20px;
    }
  }
}
// @include responsiveMax($iPhone5) {
//   .budget-btn {
//     padding: 23px 0 20px;
//     &-icon {
//       padding: 0;
//       margin: 0 20px 0 0;
//     }
//   }
// }
// @include responsiveMax($GalaxyS5) {
//   .budget-btn {
//     padding: 23px 0 20px;
//     &-icon {
//       padding: 0;
//       margin: 0 20px 0 0;
//     }
//   }
// }
// @include responsiveMin($XXldesktop) {
//   .budget-btn {
//     margin: 40px 20px;
//   }
// }
// @include responsiveMin($XXXldesktop) {
//   .budget-btn {
//     margin: 40px 40px;
//   }
// }

// Generic buttons
.outline-btn {
  background-color: white;
  border: 1px solid #282828;
  border-radius: 4px;
  color: $primary-color;
  font-size: 0.875rem;
  &:hover {
    background-color: darken(white, 5);
  }
  &-large {
    background-color: white;
    border: 1px solid #282828;
    border-radius: 4px;
    color: $primary-color;
    font-size: 1.1rem;
    &:hover {
      background-color: darken(white, 5);
    }
  }
  @include mediaMd {
    max-width: 200px;
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
}
// green
.approve-btn {
  background-color: $success-color;
  border: 1px solid darken($success-color, 10);
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  background-image: linear-gradient(
    -180deg,
    $success-color 0,
    darken($success-color, 5) 100%
  );
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: darken($success-color, 8);
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
} // red
.deny-btn {
  background-color: $danger-color;
  border: 1px solid darken($danger-color, 10);
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  background-image: linear-gradient(
    -180deg,
    $danger-color 0,
    darken($danger-color, 5) 100%
  );
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: darken($danger-color, 18);
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
}
// green outline
.approve-outline-btn {
  background-color: white;
  border: 1px solid $success-color;
  border-radius: 4px;
  color: $success-color;
  font-size: 0.875rem;
  &:hover {
    background-color: lighten($success-color, 43);
  }
  @include mediaMd {
    max-width: 200px;
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
}
// red outline
.deny-outline-btn {
  background-color: white;
  border: 1px solid $danger-color;
  border-radius: 4px;
  color: $danger-color;
  font-size: 0.875rem;
  &:hover {
    background-color: lighten($danger-color, 28);
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
}
// yellow outline
.warning-outline-btn {
  background-color: white;
  border: 1px solid $warning-color;
  border-radius: 4px;
  color: $warning-color;
  font-size: 0.875rem;
  &:hover {
    background-color: lighten($warning-color, 28);
  }
  &:disabled,
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
}

// Remove unwanted styling from Link components
.clear-link-btn {
  display: inline-block;
  text-decoration: none;
  color: #282828;
}

// MCC verify check icon (in Create a purchase)
.verify-check-icon {
  color: $success-color;
}

//Log out Button on Account page
.logout-btn {
  margin: 0;
  padding: 0 0 0 40px;
  &:hover {
    color: darken(#282828, 50);
    text-decoration: underline;
  }
  @include responsiveMax($iPhone5) {
    padding: 0 0 0 20px;
    font-size: 14px;
  }
}

.budget-change-btn {
  margin: 0 0 0 0.5em;
  padding: 0;
  font-size: 12px;
  color: $med-grey-color;
  text-align: left;
  position: relative;
  left: 0;
  &:hover {
    color: darken(#282828, 50);
    text-decoration: underline;
  }
  // @include responsiveMax($iPhone5) {
  //   padding: 0 0 0 20px;
  //   font-size: 14px;
  // }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.view-balance-btn {
  font-size: 13px;
  color: $med-grey-color;
  text-align: left;
}
