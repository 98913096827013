.filepond--root {
    margin-bottom: 40px;
    background-color: #ffffff;
}
.filepond--drop-label {
    background-color: #EDF5F8;
    border: 1px dashed #637381;
    border-radius: 8px;
    padding: 100px 0px;
}
.filepond--item {
    margin: 20px 0px;
}