// Card List Item
.card-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #d8d9dd;

  align-items: center;
  justify-content: space-between;
  padding-right: 20px;

  font-size: 15px;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;


  &-cardnumber {
    flex: 0 0 25%;
    padding: 10px 10px 10px 10px;
    overflow-x: hidden;
  }
  &-cardimage {
    flex: 0 0 8%;
    padding: 10px 0 10px 10px;
    overflow-x: hidden;
    align-items: center;
  }
  &-cardstatus {
    color: #29cc97;
    flex: 0 0 15%;
    padding: 0 10px;
    /* font-weight: bold; */
    &-approval {
      color: $warning-color;
      flex: 0 0 5%;
      padding: 5px;
    }
    &-active {
      color: #29cc97;
      flex: 0 0 5%;
      // padding: 5px;
    }
    &-inactive {
      color: #fd4d4d;
      flex: 0 0 5%;
      // padding: 5px;
      /* font-weight: bold; */
    }
  }
  &-statustext {
    // flex: 0 0 20%;
    padding: 0 10px;
    font-weight: bold;
  }
  &-holdbtn {
    flex: 0 0 15%;
  }
  &-unholdbtn {
    flex: 0 0 17%;
  }
  &-deletebtn {
    flex: 0 0 10%;
  }
  &-btngroup {
    flex: 0 0 25%;
  }
}
.card-list {
  margin-top: 15px;
}

.trash-icon {
  padding: 1px;
}

@include mediaMd {
  /* Designate Grid Items */
  .card-list-item {
    &-cardnumber {
      grid-area: cardnumber;
      padding: 0 5px 0 0;
      font-size: 16px;
      overflow: hidden;
      min-width: 100%;
      max-height: 25px;
    }
    &-cardstatus {
      grid-area: cardstatus;
      padding: 0;
      font-size: 16px;
      text-align: left;
      overflow: hidden;
      max-width: 20px;
    }

    &-statustext {
      padding: 0;
      grid-area: statustext;
      text-align: right;
      font-size: 14px;
    }
    &-holdbtn {
      grid-area: holdbtn;
      padding: 10px 0 0 0;
      font-size: 12px;
      overflow: hidden;
      & span {
        display: none;
      }
    }
    &-unholdbtn {
      grid-area: unholdbtn;
      padding: 10px 0 0 0;
      font-size: 12px;
      overflow: hidden;
      & span {
        display: none;
      }
    }
    &-deletebtn {
      grid-area: deletebtn;
      padding: 10px 0 0 0;
      // text-align: center;
      // font-size: 14px;
      // height: 5px;
      // width: 5px;
    }
    &-cardimage {
      display: none;
    }

    /* Set Up Grid */
    display: grid;
    grid-template-areas:
      'cardnumber cardnumber cardnumber'
      'cardstatus statustext statustext'
      'holdbtn unholdbtn deletebtn';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
}
@include responsiveMax($iPhone5) {
  .card-list-item {
    min-width: 320px;
  }
}
