* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-family: 'Muli', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.container {
  min-height: 100%;
  box-sizing: inherit;
  // &.navbar-fixed {
  //   position: fixed;
  // }
}
//   height: 100%;
//   z-index: 0;
// }

.wrapper {
  display: flex;
  flex-grow: 1;
  @include themed() {
    background-color: t($bg);
  }
  &.menu-fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.footer {
  position: absolute;
  visibility: hidden;
  // margin-top: 100vh;
  @include responsiveMax($desktopMin) {
    position: inherit;
    margin-bottom: 3rem;
  }
}

@include mediaMd {
  .container {
    // margin-top: 10vh;
    margin-right: 0;
  }
}
