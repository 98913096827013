.success {
  color: #29cc97;
}

.warning {
  color: #fec810;
}

.danger {
  color: #fd4d4d;
}

.light-grey {
  color: #dfe0eb;
}

.med-grey {
  color: #637381;
}

.middle-grey {
  color: #85877c;
}

.grey {
  color: #6c6c72;
}

.flame {
  color: #e4572e;
}

.vblue {
  color: #52d9ff;
}

.vendrix-blue-700 {
  color: #0173bc;
}

.vendrix-blue-800 {
  color: #0d5d73;
}

.inline-bar {
  display: inline-block;
  border-bottom: 1px solid #52d9ff;
  width: 100%;
  height: 0;
  margin: 0;
}

@media (max-width: 769px) {
  .hide-mobile {
    display: none !important;
  }
}

.required-action-text {
  color: #e4572e !important;
  font-weight: bold !important;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: 'Muli', sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  min-height: 100%;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.theme--default .wrapper {
  background-color: white;
}

.theme--dark .wrapper {
  background-color: #282828;
}

.wrapper.menu-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer {
  position: absolute;
  visibility: hidden;
}

@media (max-width: 769px) {
  .footer {
    position: inherit;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-right: 0;
  }
}

.header {
  z-index: 1000;
  position: relative;
}

.theme--default .header {
  background-color: white;
  -webkit-box-shadow: 0 0 4px rgba(193, 193, 193, 0.35);
  box-shadow: 0 0 4px rgba(193, 193, 193, 0.35);
}

.theme--dark .header {
  background-color: #282828;
  -webkit-box-shadow: 0 0 4px rgba(193, 193, 193, 0.35);
  box-shadow: 0 0 4px rgba(193, 193, 193, 0.35);
}

.header-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.header-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3vw;
}

.header-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.vlogo {
  background-repeat: no-repeat;
  margin: auto 0;
  height: 32px;
  width: 125px;
}

.theme--default .vlogo {
  background-image: url('../../assets/vlogo.svg');
  color: 'primary-color';
}

.theme--dark .vlogo {
  background-image: url('../../assets/vlogo.svg');
  color: white;
}

.vlogo span {
  text-align: center;
  padding: 20px 0 0 35px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
}

.private-beta {
  float: right;
  padding-top: 0;
}

header h1 {
  color: #282828;
  font-size: 1.05rem;
  margin: auto 51px;
}

.theme--default header h1 {
  color: #000;
}

.theme--dark header h1 {
  color: white;
}

header p {
  font-size: 0.725rem;
  font-weight: 200;
  padding-top: 4px;
  color: #637381;
}

.profile-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  text-align: right;
  float: right;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 200;
  color: #637381;
  min-height: 10vh;
  padding: 0 40px;
}

.profile-icon:hover {
  background-color: #f6f6f6;
}

.circle-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.refresh-icon {
  margin-right: 10px;
  cursor: pointer;
}

.theme--default .refresh-icon {
  color: 'primary-color';
}

.theme--dark .refresh-icon {
  color: white;
}

.refresh-icon:hover {
  color: #52d9ff;
}

.profile-icon span {
  display: none;
}

@media screen and (max-width: 768px) {
  .header-left-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-right-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto;
  }
  .vlogo {
    background-repeat: no-repeat;
    margin: 9px 0 0 8vw;
    height: 32px;
    width: 125px;
  }
  .theme--default .vlogo {
    background-image: url('../../assets/vlogo.svg');
    color: 'primary-color';
  }
  .theme--dark .vlogo {
    background-image: url('../../assets/vlogo.svg');
    color: white;
  }
  .vlogo span {
    text-align: center;
    padding: 25px 0 0 35px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
  }
  header h1 {
    display: none;
  }
  .profile-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    text-align: right;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 400;
    color: #747892;
    padding: 0 20px 0 0;
  }
  .nav-user-role-name {
    display: none;
  }
  .profile-icon span {
    display: none;
  }
  .circle-img {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .menu-btn {
    position: fixed;
    z-index: 3;
    left: 20px;
    bottom: 20px;
    padding: 20px 0 0 15px;
    border-radius: 50%;
    border: 2px solid #e6e6e6;
    -webkit-box-shadow: -2px 8px 5px #bcbeca;
    box-shadow: -2px 8px 5px #bcbeca;
    width: 60px;
    height: 60px;
    cursor: pointer;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
  }
  .theme--default .menu-btn {
    background-color: white;
  }
  .theme--dark .menu-btn {
    background-color: #282828;
  }
  .menu-btn.close {
    -webkit-transform: translate3d(116px, 0, 0);
    transform: translate3d(116px, 0, 0);
  }
  .menu-btn.close .btn-line:nth-child(1) {
    opacity: 0;
  }
  .menu-btn.close .btn-line:nth-child(2) {
    -webkit-transform: rotate(45deg) translate(7px, 5px);
    transform: rotate(45deg) translate(7px, 5px);
    width: 20px;
  }
  .menu-btn.close .btn-line:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(10px, -7px);
    transform: rotate(-45deg) translate(10px, -7px);
    width: 20px;
  }
  .btn-line {
    width: 28px;
    height: 2px;
    margin: 0 0 5px 0;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
  }
  .theme--default .btn-line {
    background-color: #282828;
  }
  .theme--dark .btn-line {
    background-color: #fff;
  }
  .btn-line:nth-child(1) {
    width: 10px;
  }
  .btn-line:nth-child(2) {
    width: 20px;
  }
}

.nav-layout {
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #eaeaea;
  z-index: 1;
}

.theme--default .nav-layout {
  background-color: #fbfafa;
}

.theme--dark .nav-layout {
  background-color: #282828;
}

.nav-link {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #6c6c72;
  font-weight: 400;
  font-size: 16px;
  padding: 5px 0;
  margin: 10px 0;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.nav-link-active {
  font-weight: 700;
}

.theme--default .nav-link-active {
  color: 'primary-color';
}

.theme--dark .nav-link-active {
  color: white;
}

.nav-link-active .nav-icon {
  color: #1fceff;
}

.nav-link-container {
  width: 120px;
}

.nav-link-container:hover .nav-word-container {
  font-weight: 700;
}

.theme--default .nav-link-container:hover .nav-word-container {
  color: 'primary-color';
}

.theme--dark .nav-link-container:hover .nav-word-container {
  color: white;
}

.nav-link-container:hover .nav-icon-container {
  color: #1fceff;
}

.nav-icon {
  margin-right: 10px;
}

.nav-footer {
  position: relative;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 10px 0;
}

.nav-close-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 50%;
  margin-top: 10px;
  color: #363740;
  opacity: 50%;
  font-weight: 700;
  position: absolute;
  bottom: 1%;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.portal-link {
  margin: 9px 10px 0 0;
  height: 18px;
  width: 18px;
}

.nav-modal {
  position: relative;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}

.spinner-container {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.logo-spinner {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;
  margin: auto;
}

.logo-spinner-img {
  display: block;
  width: 60px;
  margin: auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
}
@keyframes sk-rotateplane {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.unlinked-account {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;
}

.unlinked-account-text {
  padding-bottom: 20px;
}

.unlinked-account-subtext {
  padding-bottom: 20px;
}

.mfa-page-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 80px;
  height: 100%;
  min-height: 100vh;
  background-color: #dfe0eb;
}

@media screen and (max-width: 500px) {
  .mfa-page-container {
    padding: 0;
  }
}

.mfa-widget-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  width: 400px;
  white-space: normal;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 40px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 40px;
}

@media screen and (max-width: 500px) {
  .mfa-widget-container {
    padding: 0;
    width: 100%;
    border-radius: 0px;
  }
}

.mfa-container-header {
  padding: 40px 40px 24px;
  line-height: 1.5;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.mfa-container-header-item {
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 100%;
  vertical-align: baseline;
}

.mfa-container-header-title {
  margin: 24px 0;
  font-size: 24px;
}

.mfa-container-header-text {
  font-size: 14px;
  color: #282828;
}

.mfa-container-logo {
  max-height: 52px;
  max-width: 100%;
  vertical-align: top;
}

.mfa-container-input-item {
  padding: 0px 40px 30px;
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.mfa-container-input-phone {
  width: 100%;
  color: #2d333a;
  padding: 16px;
}

.mfa-button-submit {
  width: 100%;
  background: #282828;
  color: white;
  padding: 20px;
  margin: 10px 0;
  border: 0;
  border-radius: 3px;
  font-size: 16px;
}

.mfa-container-input-code {
  font-size: 16px;
  border: 1px solid #c2c8d0;
  height: 52px;
  line-height: 1.1;
  outline: none;
  padding: 0 16px;
  width: 100%;
  background-color: white;
  transition-transition-property: box-shadow, border-color 0.2s ease-in-out;
  transition-transition-duration: 0.2s, 0.2s;
  transition-transition-timing-function: ease-in-out, ease-in-out;
  transition-transition-delay: 0s, 0s;
  border-radius: 3px;
}

.mfa-browser-check {
  display: table-cell;
  vertical-align: middle;
  width: 2000px;
  height: 900px;
  background-color: transparent;
}

.mfa-browser-check-text {
  padding-bottom: 20px;
}

.mfa-browser-check-subtext {
  padding-bottom: 20px;
}

.page-container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px 40px;
  display: block;
}

.theme--default .page-container {
  background-color: white;
}

.theme--dark .page-container {
  background-color: #282828;
}

.page-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.page-heading h1 {
  display: none;
}

.theme--default .page-heading-text {
  color: 'primary-color';
}

.theme--dark .page-heading-text {
  color: white;
}

.page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home-page-yourcard {
  padding: 0;
}

.transaction-page-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 90%;
  margin: 0;
  padding: 0 0 20px;
}

.theme--default .transaction-page-heading-text {
  color: 'primary-color';
}

.theme--dark .transaction-page-heading-text {
  color: white;
}

@media (max-width: 768px) {
  .page-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 0;
    overflow: none;
    text-align: left;
    margin-bottom: 0;
  }
  .page-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .page-heading h1 {
    display: block;
    text-align: left;
    font-size: 1.1rem;
    margin-bottom: 1vh;
  }
  .theme--default .page-heading h1 {
    color: 'primary-color';
  }
  .theme--dark .page-heading h1 {
    color: white;
  }
  .page-heading-text {
    font-size: 15px;
    margin-bottom: 1.5vh;
  }
  .theme--default .page-heading-text {
    color: 'primary-color';
  }
  .theme--dark .page-heading-text {
    color: white;
  }
  .page-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0px 30px;
  }

  .page-list-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 50px;
  }
}

@media (max-width: 320px) {
  .page-actions {
    padding: 0px 20px;
  }
  .page-container {
    padding-bottom: 40px;
  }
}

@media (max-width: 360px) {
  .page-actions {
    padding: 0px 20px;
  }
  .page-container {
    padding-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .transaction-page-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .transaction-page-heading h1 {
    display: block;
    text-align: left;
    font-size: 1.1rem;
    margin-bottom: 1vh;
  }
  .theme--default .transaction-page-heading h1 {
    color: 'primary-color';
  }
  .theme--dark .transaction-page-heading h1 {
    color: white;
  }
  .transaction-page-heading-text {
    font-size: 15px;
    margin-bottom: 1.5vh;
  }
  .theme--default .transaction-page-heading-text {
    color: 'primary-color';
  }
  .theme--dark .transaction-page-heading-text {
    color: white;
  }
}

.section-title {
  font-family: 'Muli', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 15px 23px 5px 43px;
}

.theme--default .section-title {
  color: 'primary-color';
}

.theme--dark .section-title {
  color: white;
}

.section-title-detail {
  font-weight: 700;
  padding: 15px 23px 5px 43px;
  font-size: 12px;
  position: relative;
  margin: 20px 45% 20px 0;
  text-align: left;
}

.section-title span {
  font-size: 0.875rem;
  font-weight: 400;
}

.section-bar {
  border-bottom: 1px solid 'primary-color';
  bottom: 0;
  display: block;
  left: 0;
  margin-top: 1vh;
  right: 0;
  width: 25%;
}

.section-bar-detail {
  border: 1px solid #d8d9dd;
  width: 80%;
  height: 0;
  margin: 0;
}

.section-bar-list {
  border: 1px solid #d8d9dd;
  width: 100%;
  height: 0;
  margin: 0 0 1vh 0;
}

@media screen and (max-width: 768px) {
  .section-title {
    padding: 15px 10px 15px 30px;
  }
  .section-bar-detail {
    width: 100%;
  }
  .transaction-section-title {
    padding: 15px 10px 15px 30px;
  }
}

.transaction-section-title {
  font-family: 'Muli', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 15px 23px 5px 43px;
}

.theme--default .transaction-section-title {
  color: 'primary-color';
}

.theme--dark .transaction-section-title {
  color: white;
}

.transaction-section-title-detail {
  font-weight: 700;
  padding: 15px 23px 5px 43px;
  font-size: 12px;
  position: relative;
  margin: 20px 45% 20px 0;
  text-align: left;
}

.transaction-section-title span {
  font-size: 0.875rem;
  font-weight: 400;
}

.paginator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.paginator-control {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #626668;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 8px;
}

.paginator-control:hover {
  background-color: #f2f2f2;
}

.paginator-control:disabled {
  background-color: #d7dbdb;
  cursor: default;
}

.paginator-button {
  background-color: #fff;
  border: 1px solid #d7dbdb;
  color: #818487;
  border-radius: 3px;
  font-size: 20px;
  margin: 0.25rem 0.5rem;
  padding: 5px 5px 3px 5px;
}

.paginator-button-showall {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d7dbdb;
  color: #818487;
  border-radius: 3px;
  font-weight: 700;
  font-size: 12px;
  margin-left: 0.5rem;
  padding: 5px 10px 3px 10px;
}

.paginator-button-showall:hover {
  background-color: #f2f2f2;
  border: 1px solid #a1abab;
}

.paginator-button-showall:disabled {
  background-color: #d7dbdb;
  border: 1px solid #a1abab;
  cursor: default;
}

.sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.sort-control {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #626668;
  padding: 0 5px 0 0;
}

.sort-button {
  background-color: #fff;
  color: #818487;
  font-size: 20px;
  margin-left: 0.5rem;
  padding: 5px 0;
}

.warning-bar {
  border: 1px solid #fec810;
  width: 100%;
  height: 0;
  margin: 0;
}

.selector-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.selector-column {
  display: block;
}

.selector {
  display: inline;
}

.selector-bar {
  border: 1px solid #dfe0eb;
  width: 100%;
  height: 0;
  margin: 0;
}

.half {
  width: 50%;
}

.ninety {
  width: 90%;
}

@media screen and (max-width: 768px) {
  .paginator {
    padding: 10px 0;
    margin-bottom: 0 !important;
  }
  .paginator-button {
    margin: 0 0.5rem;
    padding: 5px 10px 3px 10px;
  }
  .sort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 10px 0;
  }
  .sort-control {
    padding: 0 5px 0 10px;
  }
}

.banner-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 3500;
}

.banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 20px 10px;
  width: 100%;
  text-decoration: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 18px;
  font-weight: 500;
}

.success-banner {
  border-top: 1px solid #29cc97;
  border-bottom: 1px solid #29cc97;
  background-color: #f7fdfb;
}

.danger-banner {
  border-top: 1px solid #fd4d4d;
  border-bottom: 1px solid #fd4d4d;
  background-color: #ffe4e4;
}

.pending-approval-banner {
  padding: 10px 0;
  border-top: 1px solid #fec810;
  border-bottom: 1px solid #fec810;
  background-color: #fffaea;
}

.pending-approval-banner:hover {
  background-color: #ffefb8;
}

.error-banner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid #fd4d4d;
  border-bottom: 1px solid #fd4d4d;
  background-color: #fecbcb;
}

.error-banner:hover {
  background-color: #ffdada;
}

.error-banner-x-btn {
  display: inline-block;
  background: var(--light-color);
  color: 'primary-color';
  border: none;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  outline: none;
  text-decoration: none;
  text-align: center;
  padding: 0.4rem 1.3rem;
}

.banner-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.banner-section-title {
  font-weight: 700;
  text-align: center;
}

.banner-heading-text {
  font-size: 14px;
  color: #637381;
  padding: 0 40px;
}

.banner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.banner-link-text {
  font-size: 14px;
  text-align: center;
  padding: 2px 0 10px;
  font-weight: 400;
}

.error {
  font-size: 12px;
  color: var(--red-600);
  margin-top: 0.25rem;
}

@media (prefers-color-scheme: dark) {
  .error {
    color: var(--red-400);
  }
}

@media screen and (max-width: 768px) {
  .pending-approval-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    margin: 10;
    padding: 0 10px;
    width: 100%;
    text-decoration: none;
    border-top: 1px solid #fec810;
    border-bottom: 1px solid #fec810;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: #fffaea;
  }
  .pending-approval-banner:hover {
    background-color: #ffefb8;
  }
  .banner-section-title {
    font-weight: 700;
    padding: 10px 0 3px;
    font-size: 15px;
    text-align: center;
  }
  .banner-heading-text {
    font-size: 14px;
    color: #637381;
    padding: 0 40px;
  }
  .banner-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .banner-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .banner-link-text {
    font-size: 14px;
    text-align: center;
    padding: 2px 0 10px;
    font-weight: 400;
  }
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: 'primary-color';
  margin-right: 0.5rem;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-light {
  background: var(--light-color);
  color: #333;
}

.btn-bold {
  font-weight: 700;
}

.btn-edit {
  margin: 0 0 0 0.5rem;
}

.create-btn {
  cursor: pointer;
  height: auto;
  min-width: 300px;
  margin: 40px 10px;
  padding: 15px 0 15px 32px;
  text-decoration: none;
  border: 1px solid #52d9ff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  letter-spacing: 0.2px;
}

.theme--default .create-btn {
  background-color: white;
  color: 'primary-color';
  -webkit-box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
}

.theme--dark .create-btn {
  background-color: #282828;
  color: white;
  -webkit-box-shadow: -2px 0px 4px #dfe0eb;
  box-shadow: -2px 0px 4px #dfe0eb;
}

.create-btn p {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.2px;
  text-decoration: none;
}

.theme--default .create-btn p {
  color: rgba(54, 55, 64, 0.5);
}

.theme--dark .create-btn p {
  color: #dfe0eb;
}

.create-btn-icon {
  margin: 0 20px 0 40px;
}

.create-btn:hover {
  background-color: #f0fcff;
}

@media screen and (max-width: 768px) {
  .create-btn {
    padding: 20px 0 20px 32px;
    margin: 20px 0 20px;
  }
  .create-btn-icon {
    margin-right: 20px;
  }
}

@media (max-width: 320px) {
  .create-btn {
    padding: 20px 0 20px 20px;
  }
  .create-btn-icon {
    padding: 0;
    margin: 0 20px 0 0;
  }
}

@media (max-width: 360px) {
  .create-btn {
    padding: 20px 0 20px 20px;
  }
  .create-btn-icon {
    padding: 0;
    margin: 0 20px 0 0;
  }
}

@media (min-width: 1400px) {
  .create-btn {
    margin: 40px 20px;
  }
}

@media (min-width: 1550px) {
  .create-btn {
    margin: 40px 40px;
  }
}

.budget-btn {
  cursor: inherit;
  height: auto;
  min-width: 300px;
  margin: 40px 10px;
  padding: 15px 0;
  text-decoration: none;
  border: 1px solid #29cc97;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.theme--default .budget-btn {
  background-color: white;
  color: 'primary-color';
  -webkit-box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
}

.theme--dark .budget-btn {
  background-color: #282828;
  color: white;
  -webkit-box-shadow: -2px 0px 4px #dfe0eb;
  box-shadow: -2px 0px 4px #dfe0eb;
}

.budget-btn p {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.2px;
  text-decoration: none;
}

.budget-btn p:hover {
  color: #282828;
}

.theme--default .budget-btn p {
  color: rgba(54, 55, 64, 0.5);
}

.theme--dark .budget-btn p {
  color: #dfe0eb;
}

.budget-btn-icon {
  margin: 0 20px 0 40px;
}

.budget-btn-namerow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  padding-left: 32px;
}

.budget-btn-autobudget-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  text-decoration: none;
  color: #282828;
  letter-spacing: 0.2px;
  margin-top: 10px;
  padding: 10px 15px 10px 32px;
  border-radius: 10px;
}

.budget-btn-autobudget-item-displayname {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

.budget-btn-autobudget-item-budgetname {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.2px;
  color: #59a1c9;
  text-decoration: none;
  max-width: 140px;
  max-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  /* Don't forget this one */
  text-overflow: ellipsis;
  scroll-behavior: none;
}

.budget-btn-autobudget-item-amountremaining {
  color: #29cc97;
  font-weight: bold;
  padding-right: 20px;
  text-align: right;
}

.budget-btn-autobudget-item:hover {
  background-color: #eefcf7;
}

.no-autobudgets {
  font-size: 18px;
}

.autobudget-btn-p {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.2px;
  padding-left: 32px;
  text-decoration: none;
}

.theme--default .autobudget-btn-p {
  color: rgba(54, 55, 64, 0.5);
}

.theme--dark .autobudget-btn-p {
  color: #dfe0eb;
}

@media screen and (max-width: 768px) {
  .budget-btn {
    padding: 23px 0 20px;
    margin: 20px 0 20px;
  }
  .budget-btn-autobudget-item {
    padding: 10px 0 10px 32px;
  }
  .budget-btn-icon {
    margin-right: 20px;
  }
}

@media (max-width: 320px) {
  .budget-btn {
    padding: 23px 0 20px;
  }
  .budget-btn-icon {
    padding: 0;
    margin: 0 20px 0 0;
  }
}

@media (max-width: 360px) {
  .budget-btn {
    padding: 23px 0 20px;
  }
  .budget-btn-icon {
    padding: 0;
    margin: 0 20px 0 0;
  }
}

@media (min-width: 1400px) {
  .budget-btn {
    margin: 40px 20px;
  }
}

@media (min-width: 1550px) {
  .budget-btn {
    margin: 40px 40px;
  }
}

.outline-btn {
  background-color: white;
  border: 1px solid #282828;
  border-radius: 4px;
  color: 'primary-color';
  font-size: 0.875rem;
}

.outline-btn:hover {
  background-color: #f2f2f2;
}

.outline-btn-large {
  background-color: white;
  border: 1px solid #282828;
  border-radius: 4px;
  color: 'primary-color';
  font-size: 1.1rem;
}

.outline-btn-large:hover {
  background-color: #f2f2f2;
}

@media screen and (max-width: 768px) {
  .outline-btn {
    max-width: 200px;
  }
}

.outline-btn:disabled,
.outline-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.approve-btn {
  background-color: #29cc97;
  border: 1px solid #20a278;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
    -webkit-box-shadow 150ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #29cc97),
    to(#25b787)
  );
  background-image: linear-gradient(-180deg, #29cc97 0, #25b787 100%);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
}

.approve-btn:hover {
  background-color: #22aa7e;
}

.approve-btn:disabled,
.approve-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.deny-btn {
  background-color: #fd4d4d;
  border: 1px solid #fc1b1b;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
    -webkit-box-shadow 150ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fd4d4d),
    to(#fd3434)
  );
  background-image: linear-gradient(-180deg, #fd4d4d 0, #fd3434 100%);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
}

.deny-btn:hover {
  background-color: #ec0303;
}

.deny-btn:disabled,
.deny-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.approve-outline-btn {
  background-color: white;
  border: 1px solid #29cc97;
  border-radius: 4px;
  color: #29cc97;
  font-size: 0.875rem;
}

.approve-outline-btn:hover {
  background-color: #d9f7ed;
}

@media screen and (max-width: 768px) {
  .approve-outline-btn {
    max-width: 200px;
  }
}

.approve-outline-btn:disabled,
.approve-outline-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.deny-outline-btn {
  background-color: white;
  border: 1px solid #fd4d4d;
  border-radius: 4px;
  color: #fd4d4d;
  font-size: 0.875rem;
}

.deny-outline-btn:hover {
  background-color: #ffdada;
}

.deny-outline-btn:disabled,
.deny-outline-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.warning-outline-btn {
  background-color: white;
  border: 1px solid #fec810;
  border-radius: 4px;
  color: #fec810;
  font-size: 0.875rem;
}

.warning-outline-btn:hover {
  background-color: #ffe99e;
}

.warning-outline-btn:disabled,
.warning-outline-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.clear-link-btn {
  display: inline-block;
  text-decoration: none;
  color: #282828;
}

.verify-check-icon {
  color: #29cc97;
}

.logout-btn {
  margin: 0;
  padding: 0 0 0 40px;
}

.logout-btn:hover {
  color: black;
  text-decoration: underline;
}

@media (max-width: 320px) {
  .logout-btn {
    padding: 0 0 0 20px;
    font-size: 14px;
  }
}

.budget-change-btn {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #637381;
  text-align: left;
  position: relative;
  left: 0;
}

.budget-change-btn:hover {
  color: black;
  text-decoration: underline;
}

@media (max-width: 320px) {
  .budget-change-btn {
    padding: 0 0 0 20px;
    font-size: 14px;
  }
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.view-balance-btn {
  font-size: 13px;
  color: #637381;
  text-align: left;
}

.card-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

@media (max-width: 320px) {
  .card-row {
    font-size: 14px;
  }
}

.account-profile-pic {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 60px;
  height: 60px;
  margin: 0 10px 0 0;
}

.card-detail-preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 20px 40px;
  margin: 0 0 20px 20px;
  border-bottom: 1px solid lightgrey;
  background: #fbfbfb;
}

@media screen and (max-width: 768px) {
  .card-detail-preview {
    padding: 10px;
    margin: 0;
  }
}

.active-auto-expense {
  color: #29cc97;
  padding-left: 15px;
  font-weight: bold;
}

.chartDashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.mongo-charts {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#numberTransactionsChart {
  height: 150px;
  width: 300px;
}

#accountBalanceChart {
  height: 150px;
  width: 300px;
}

#transactionsByMerchantChart {
  height: 300px;
  width: 300px;
}

#remainingBudgetChart {
  height: 300px;
  width: 300px;
}

#customerLocationChart {
  height: 300px;
  width: 300px;
}

#amountByMerchantChart {
  height: 400px;
  width: 300px;
}

#spendByUserChart {
  height: 400px;
  width: 300px;
}

@media (min-width: 991px) {
  /* Designate Grid Items */
  #numberTransactionsChart {
    height: 150px;
    width: 300px;
  }
  .numberCardTransactions {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: numberCardTransactions;
  }
  #accountBalanceChart {
    height: 150px;
    width: 300px;
  }
  .accountBalance {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: accountBalance;
  }
  #transactionsByMerchantChart {
    height: 320px;
    width: 500px;
  }
  .transactionsByMerchant {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-area: transactionsByMerchant;
  }
  #remainingBudgetChart {
    height: 300px;
    width: 480px;
  }
  .remainingBudgetAmount {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    grid-area: remainingBudgetAmount;
  }
  #customerLocationChart {
    height: 400px;
    width: 820px;
  }
  .customerLocations {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: customerLocations;
  }
  #amountByMerchantChart {
    width: 480px;
    height: 400px;
  }
  .amountByMerchant {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: amountByMerchant;
  }
  #spendByUserChart {
    height: 400px;
    width: 1300px;
  }
  .spendByUser {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: spendByUser;
  }
  .mongo-charts {
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0 0.2rem 0.5rem 0 rgba(6, 22, 33, 0.22);
    box-shadow: 0 0.2rem 0.5rem 0 rgba(6, 22, 33, 0.22);
  }
  .chartDashboard {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'numberCardTransactions transactionsByMerchant remainingBudgetAmount '
      'accountBalance transactionsByMerchant remainingBudgetAmount '
      'customerLocations customerLocations amountByMerchant '
      'spendByUser spendByUser spendByUser ';
    grid-gap: 20px;
    border: none;
    margin: 0;
    padding: 0;
  }
}

.credit-indicator-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 0 0 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 300px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
}

@media (min-width: 769px) {
  .credit-indicator-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0 5px 43px;
    text-align: left;
    width: 400px;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.credit-indicator-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.credit-indicator-title {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  padding-bottom: 5px;
}

.credit-indicator-label {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  color: #637381;
  padding-left: 1.3rem;
}

.credit-indicator-value {
  font-size: 16px;
  color: 'primary-color';
  padding-left: 10px;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: relative;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -webkit-box-shadow: 0 0 1px 2px #00cae9;
  box-shadow: 0 0 1px 2px #00cae9;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30vh) rotate(-45deg);
    transform: translateY(-30vh) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30vh) rotate(-45deg);
    transform: translateY(-30vh) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

/* ------------------------------ */
/* ! modal-main receipt section ! */
/* ------------------------------ */
.modal-main-receipt {
  position: fixed;
  background: white;
  width: 100%;
  height: 90%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 0;
}

@media (min-width: 768px) {
  .modal-main-receipt {
    position: fixed;
    background: white;
    width: 80%;
    height: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 10px 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1025px) {
  .modal-main-receipt {
    position: fixed;
    background: white;
    text-align: center;
    width: 50%;
    height: 70%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 30px 30px 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1171px) {
  .modal-main-receipt {
    position: fixed;
    background: white;
    text-align: center;
    width: 50%;
    height: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 30px 30px 10px;
  }
}

.notification-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.notification-modal-main {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0 10px 20px;
}

.notification-modal-main.danger {
  color: #282828;
  border: 1px solid #fd4d4d;
  border-radius: 5px;
  background-color: #fff3f3;
}

.notification-modal-main.success {
  color: #282828;
  border: 1px solid #29cc97;
  border-radius: 5px;
  background-color: #eafbf5;
}

.notification-modal-close-btn {
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: none;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
}

.notification-modal-confirm-btn {
  font-weight: 700;
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: none;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
  padding: 10px 20px;
}

.notification-modal-cancel-btn {
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: none;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .notification-modal-main {
    top: 50%;
    width: 80%;
    font-size: 14px;
  }
}

.confirmation-modal-main {
  margin: 10% auto;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  max-width: 500px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .confirmation-modal-main {
    max-width: 330px;
  }
}

.confirmation-modal-main.danger {
  color: #282828;
  border: 1px solid #fd4d4d;
  border-radius: 5px;
  background-color: #fff3f3;
}

.confirmation-modal-main.success {
  color: #282828;
  border: 1px solid #29cc97;
  border-radius: 5px;
  background-color: #eafbf5;
}

.confirmation-modal-btn-row {
  padding: 20px 0;
}

.confirmation-modal-close-btn {
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: none;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
}

.confirmation-modal-confirm-btn {
  font-weight: 700;
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: 0.5px solid #fff3f3;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
  padding: 10px 20px;
  margin-right: 5px;
}

.confirmation-modal-confirm-btn:hover {
  background-color: #ffe4e4;
  border-radius: 5px;
}

.confirmation-modal-cancel-btn {
  cursor: pointer;
  color: #282828;
  background-color: none;
  background: none;
  border: none;
  font-size: 16px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  text-transform: capitalize;
  padding: 10px 20px;
  margin-left: 5px;
}

.confirmation-modal-cancel-btn:hover {
  background-color: #ffe4e4;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .confirmation-modal-main {
    top: 50%;
    width: 80%;
    font-size: 20px;
  }
}

.form-modal-content {
  margin: 10% auto;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  max-width: 500px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .form-modal-content {
    max-width: 330px;
  }
}

.mdl_card-title-form {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: stretch;
  -webkit-box-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.form-modal-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -ms-flex-item-align: start;
  align-self: start;
  margin: 0 0 10px 8%;
}

.form-modal-container {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  width: 90%;
  text-align: left;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-modal-container-item {
  margin: 0;
  margin-top: 5vh;
  text-align: left;
}

.form-modal-container-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 6px 0 12px;
}

.form-modal-container-row-responsive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  margin: 6px 0 12px;
}

.form-modal-container-row p {
  min-width: 55%;
  max-width: 55%;
  font-size: 14px;
  padding: 0 0 0 16px;
}

@media (min-width: 769px) {
  .form-modal-container {
    margin: 0 0 0 8%;
  }
  .form-modal-container-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 5vh;
  }
  .form-modal-container-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-modal-container-row-responsive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-modal-container-row p {
    min-width: 70%;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-content {
  margin: 10% auto;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  max-width: 500px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .modal-content {
    max-width: 330px;
  }
}

.mdl_card-title {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  color: #000;
  display: block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: stretch;
  -webkit-box-pack: stretch;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  -webkit-perspective-origin: 165px 56px;
  perspective-origin: 165px 56px;
  -webkit-transform-origin: 165px 56px;
  transform-origin: 165px 56px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mdl-card__actions {
  background-color: rgba(222, 222, 222, 0.25);
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

.mdl-card__menu-form {
  padding: 10px 0 0;
  position: relative;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.mdl-title {
  padding-right: 20px;
}

.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 0 0 20px;
  display: inline-block;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.mdl-form-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.display-block {
  display: block;
  z-index: 1000;
}

.display-none {
  display: none;
}

.modal-form-main {
  width: 100%;
}

.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: 700;
  cursor: pointer;
  color: #282828;
  background-color: #85877c;
  border-radius: 4px;
  border: 1px solid #85877c;
  font-size: 16px;
  padding: 5px 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
    -webkit-box-shadow 150ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    to(#85877c)
  );
  background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
  -webkit-box-shadow: inset 0 -1px 0 0 #85877c;
  box-shadow: inset 0 -1px 0 0 #85877c;
  text-transform: capitalize;
}

.modal-close-btn-receipt {
  display: block;
  margin: 10px auto;
  font-weight: 700;
  cursor: pointer;
  color: #282828;
  background-color: #85877c;
  border-radius: 4px;
  border: 1px solid #85877c;
  font-size: 16px;
  padding: 5px 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
    -webkit-box-shadow 150ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    to(#85877c)
  );
  background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
  -webkit-box-shadow: inset 0 -1px 0 0 #85877c;
  box-shadow: inset 0 -1px 0 0 #85877c;
  text-transform: capitalize;
}

.modal-close-btn-budget {
  position: relative;
  display: block;
  margin: 10px auto;
  cursor: pointer;
  padding: 5px 20px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .display-block {
    display: block;
    z-index: 1000;
  }
  .display-none {
    display: none;
  }
  .modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: #85877c;
    border-radius: 4px;
    border: 1px solid #85877c;
    font-size: 16px;
    padding: 5px 20px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
    transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
    transition: transform 150ms, box-shadow 150ms;
    transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
      -webkit-box-shadow 150ms;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #fff),
      to(#85877c)
    );
    background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
    -webkit-box-shadow: inset 0 -1px 0 0 #85877c;
    box-shadow: inset 0 -1px 0 0 #85877c;
    text-transform: capitalize;
  }
  .modal-close-btn-receipt {
    display: block;
    margin: 10px auto;
    font-weight: 700;
    cursor: pointer;
    color: #282828;
    background-color: #85877c;
    border-radius: 4px;
    border: 1px solid #85877c;
    font-size: 16px;
    padding: 5px 20px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
    transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
    transition: transform 150ms, box-shadow 150ms;
    transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
      -webkit-box-shadow 150ms;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #fff),
      to(#85877c)
    );
    background-image: linear-gradient(-180deg, #fff 0, #85877c 100%);
    -webkit-box-shadow: inset 0 -1px 0 0 #85877c;
    box-shadow: inset 0 -1px 0 0 #85877c;
    text-transform: capitalize;
  }
}

/* Add a black background color to the top navigation */
.titlebar {
  height: 51px;
  overflow: hidden;
  border-bottom: 1px solid #d8d9dd;
  /* Style the links inside the navigation bar */
}

.theme--default .titlebar {
  background-color: #fbfafa;
}

.theme--dark .titlebar {
  background-color: #282828;
}

.titlebar-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.titlebar-list-title {
  float: left;
  color: #363740;
  text-align: center;
  padding: 14px 24px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.titlebar-list-title:hover {
  background-color: #ddd;
  color: black;
}

.titlebar-list-title.active {
  font-weight: 700;
  border-bottom: 2px solid #5c5d60;
  color: #1f1f24;
}

@media screen and (max-width: 768px) {
  .titlebar {
    display: none;
  }
}

.wrap {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.searchTerm {
  width: 100%;
  padding: 5px;
  height: 34px;
  font-size: 15px;
  font-size: 1.3vw;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
  outline: none;
  color: #282828;
  border: none;
  border-radius: 24px;
}

.searchTerm:focus {
  color: #000;
}

.searchButton {
  margin: -5 auto 0;
  width: 30px;
  height: 30px;
  border: none;
  background: #fff;
  text-align: center;
  color: rgba(54, 55, 64, 0.5);
  border-radius: 24px;
  cursor: pointer;
  font-size: 14px;
  padding-right: 13px;
}

/*Resize the wrap to see the search bar change!*/
@media (max-width: 768px) {
  .wrap {
    width: 100%;
    padding: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.detail-page-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0 20px 0 0;
}

.detail-page-title h2 {
  padding: 0;
  font-weight: 700;
  font-size: 1.3rem;
}

.detail-name-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  margin: 10px;
}

.detail-name-top-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.detail-name-top h4 {
  color: #282828;
  font-size: 18px;
  font-weight: 400;
}

.detail-name-top p {
  padding-left: 60px;
}

.detail-name-top button {
  margin-left: 60px;
}

.detail-input {
  border: 1px solid #999;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 1.2rem;
  margin: 2vh 0 0.5vh 0;
  outline: 0;
  padding: 5px 12px 5px 16px;
  width: 100%;
  overflow: hidden;
}

.detail-label {
  font-size: 14px;
  color: #637381;
  text-align: right;
  width: 200px;
}

.detail-label-top {
  text-align: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.detail-label-top-large {
  font-size: 20px;
}

.detail-label-wide {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

@media (min-width: 769px) {
  .detail-label-role {
    width: 300px !important;
  }
}

.detail-entry {
  font-size: 14px;
  color: 'primary-color';
}

.detail-entry-top {
  font-size: 22px;
  padding: 0 10px;
}

.detail-entry-top-large {
  font-size: 28px;
  padding: 0 20px;
}

.detail-text {
  margin: 0;
}

.detail-fileupload-btn {
  margin: 1vh 0 2vh;
}

.detail-back-icon {
  margin: 0 8px 0 0;
}

.detail-x-btn {
  position: relative;
  padding-right: 20px;
}

.detail-x-btn.close {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.detail-x-btn-line {
  width: 23px;
  height: 1px;
}

.theme--default .detail-x-btn-line {
  background-color: #282828;
}

.theme--dark .detail-x-btn-line {
  background-color: #fff;
}

.detail-x-btn-line:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(0.5px, 0.5px);
  transform: rotate(45deg) translate(0.5px, 0.5px);
}

.detail-x-btn-line:nth-child(2) {
  -webkit-transform: rotate(-45deg) translate(0.5px, -0.5px);
  transform: rotate(-45deg) translate(0.5px, -0.5px);
}

@media screen and (max-width: 768px) {
  .detail-page-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 0 70px 0 0;
  }
  .detail-page-title h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
  .detail-name-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .detail-input {
    border: 1px solid #999;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin: 2vh 0 0.5vh 0;
    outline: 0;
    padding: 5px 12px 5px 16px;
    width: 100%;
    overflow: hidden;
  }
  .detail-label {
    font-size: 14px;
    color: #637381;
    width: 120px;
  }
  .detail-label-top {
    text-align: left;
    width: 143px;
  }
  .detail-entry {
    font-size: 14px;
    color: 'primary-color';
  }
  .detail-entry-top {
    font-size: 22px;
    padding: 0;
    width: 75px;
    text-align: right;
  }
  .detail-text {
    margin: 0;
  }

  .detail-fileupload-btn {
    margin: 1vh 0 2vh;
  }

  .detail-x-btn {
    position: relative;
  }
}

@media (max-width: 320px) {
  .detail-page-title h2 {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  -googlemap {
    height: 30vh;
    width: 100%;
  }
  -fileupload {
    width: 60vw;
  }
}

.detail-section-title {
  font-weight: 700;
  padding: 15px 23px 5px 43px;
  font-size: 14px;
  text-align: left;
}

.detail-section-title-status {
  font-weight: 700;
  font-size: 12px;
  position: relative;
  text-align: left;
  margin: 0;
  padding: 0;
}

.detail-section-title-status span {
  font-size: 0.875rem;
  font-weight: 400;
  padding-right: 8px;
}

.detail-title-small {
  font-weight: 700;
  padding: 15px 23px 5px 63px;
  font-size: 14px;
  text-align: left;
}

.detail-top-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 10px;
  padding-left: 5px;
}

.detail-top-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.detail-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 2vw;
}

.detail-column-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 3vw;
  text-align: right;
}

.detail-column-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 3vw;
  text-align: left;
}

.detail-column-select {
  margin-right: 3vw;
  text-align: left;
  min-width: 200px;
}

.detail-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  text-align: left;
  margin-top: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.detail-row-block {
  display: inline-block;
  text-align: left;
  margin-top: 15px;
}

.detail-row-totals {
  padding: 10px 40px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 100%;
  background-color: #fff;
  border: none;
}

.detail-row-subtotals {
  padding: 10px 40px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 80%;
  background-color: #fbfafa;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.detail-responsive-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (min-width: 769px) and (max-width: 1025px) {
  .detail-responsive-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.detail-avatar-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.two-column {
  width: 115px !important;
}

.avatar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cardhold-btn {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .detail-section-title {
    font-weight: 700;
    padding: 15px 23px 5px 43px;
    font-size: 12px;
    text-align: left;
  }
  .detail-section-title-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 95%;
    margin: 15px 0 0;
    padding: 20px 20px;
  }
  .detail-section-title-status {
    width: 80%;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .detail-section-title-status span {
    font-size: 0.875rem;
    font-weight: 400;
    padding-right: 8px;
  }
  .detail-title-small {
    width: 100%;
    font-weight: 700;
    padding: 15px 23px 15px 63px;
    font-size: 12px;
    text-align: left;
  }
  .detail-top-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding-left: 5px;
  }
  .detail-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 2vw;
  }
  .detail-column-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 3vw;
    text-align: right;
  }
  .detail-column-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 3vw;
    text-align: left;
  }
  .detail-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: left;
    margin-top: 15px;
  }
  .detail-row-totals {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
  }
  .detail-responsive-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .two-column {
    width: 120px !important;
  }
}

@media (max-width: 320px) {
  .detail-row-totals {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 90%;
    padding: 5px 20px 15px;
  }
}

.budgetdetail-header-title-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  width: 80%;
  padding: 20px 0 6px;
}

@media (min-width: 769px) {
  .budgetdetail-header-title-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
    margin: 15px 0 0;
    padding: 20px 0;
  }
}

.budgetdetail-header-page-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  margin: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .budgetdetail-header-page-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 0 20px 0 0;
  }
}

.budgetdetail-header-page-title h2 {
  padding: 0;
  font-weight: 700;
  font-size: 1.3rem;
}

@media (min-width: 769px) {
  .budgetdetail-header-page-title h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
}

.budgetdetail-header-btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
  padding: 12px 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 769px) {
  .budgetdetail-header-btn-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.budgetdetail-totals-row {
  padding: 6px 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
  background-color: #fff;
  border: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1025px) {
  .budgetdetail-totals-row {
    padding: 10px 40px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 80%;
    background-color: #fff;
    border: none;
  }
}

.budgetdetail-subtotals-container {
  padding: 10px 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  background-color: #fbfafa;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1025px) {
  .budgetdetail-subtotals-container {
    padding: 10px 40px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
    background-color: #fbfafa;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.budgetdetail-subtotals-row {
  padding: 0 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 1025px) {
  .budgetdetail-subtotals-row {
    width: 100%;
    padding: 10px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media (min-width: 769px) {
  .budgetdetail-subtotals-row {
    width: 100%;
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.budget-progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #dcdcdc;
  -webkit-box-shadow: 0 3px 3px -5px #dcdcdc, 0 2px 5px #dcdcdc;
  box-shadow: 0 3px 3px -5px #dcdcdc, 0 2px 5px #dcdcdc;
  border-radius: 6px;
  position: relative;
  margin: 12px 0 6px;
  height: 15px;
  width: 80%;
  overflow: hidden;
}

@media (min-width: 769px) {
  .budget-progress-bar {
    border-radius: 10px;
    height: 20px;
    margin: 16px 0 4px;
  }
}

.budget-progress-bar-awaitingapproval {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#fec810),
    to(#dcdcdc)
  );
  background: linear-gradient(to left, #fec810, #dcdcdc);
  border-radius: 10px 0 0 10px;
}

.budget-progress-bar-approved {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#29cc97),
    to(#fec810)
  );
  background: linear-gradient(to left, #29cc97, #fec810);
}

.budget-progress-bar-pending {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#637381),
    to(#29cc97)
  );
  background: linear-gradient(to left, #637381, #29cc97);
}

.budget-progress-bar-complete {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#282828),
    to(#637381)
  );
  background: linear-gradient(to left, #282828, #637381);
  border-radius: 0 10px 10px 0;
}

.progress-done {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  -webkit-transition: 1s ease 0.3s;
  transition: 1s ease 0.3s;
}

.budgetdetail-label {
  font-size: 14px;
  color: #637381;
  width: 120px;
}

@media (min-width: 769px) {
  .budgetdetail-label {
    font-size: 14px;
    color: #637381;
    text-align: right;
    width: 200px;
  }
}

.budgetdetail-label-top {
  text-align: left;
  width: 143px;
}

@media (min-width: 769px) {
  .budgetdetail-label-top {
    text-align: right;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.budgetdetail-label-top-large {
  text-align: left;
  width: 143px;
  font-size: 20px;
}

@media (min-width: 769px) {
  .budgetdetail-label-top-large {
    font-size: 20px;
    width: auto;
  }
}

.budgetdetail-entry {
  font-size: 14px;
  color: 'primary-color';
}

@media (min-width: 769px) {
  .budgetdetail-entry {
    font-size: 14px;
    color: 'primary-color';
  }
}

.budgetdetail-entry-top {
  font-size: 22px;
  padding: 0;
  width: 75px;
  text-align: right;
}

@media (min-width: 769px) {
  .budgetdetail-entry-top {
    width: auto;
    text-align: left;
    font-size: 22px;
    padding: 0 10px;
  }
}

.budgetdetail-entry-top-large {
  font-size: 22px;
  margin: 0 10px;
  width: 75px;
  text-align: right;
}

@media (min-width: 769px) {
  .budgetdetail-entry-top-large {
    font-size: 28px;
    padding: 0 20px;
  }
}

.budget-detail-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 40px 0 20px;
  font-size: 18px;
}

.budget-member-list {
  text-decoration: none;
  list-style: none;
  display: inline;
  overflow-x: scroll;
  white-space: nowrap;
}

.budget-member-list li {
  display: inline-block;
  margin: 10px 20px 30px;
}

.member-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.member-name {
  padding: 0 5px 0 8px;
}

.budget-detail-description {
  font-size: 16px;
  margin: 10px;
}

.budget-detail-amount {
  font-size: 20px;
  font-weight: 700;
  color: green;
  margin: 10px;
}

.budget-detail-x-item {
  margin-left: auto;
}

.budget-detail-x-btn {
  font-size: 18px;
  font-weight: 700;
  color: #637381;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .budget-detail-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 40px 0 20px;
    font-size: 18px;
  }
  .budget-member-list {
    text-decoration: none;
    list-style: none;
    display: inline;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .budget-member-list li {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .form-main {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;
  }
  .form-title h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
  .form-x-btn {
    position: relative;
  }
  .form-nav {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }
  .form-submit-btn {
    width: 100%;
    text-align: center;
    top: 65%;
    margin: 5vh 0;
    padding: 0;
    text-decoration: underline;
  }
  .form-continue-btn {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 0;
    padding-left: 5vw;
  }
  .form-goback-btn {
    margin-bottom: 10px;
  }
  .form-responsive-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-field__input:focus + .form-field__label,
  .form-field__input:not(:placeholder-shown) + .form-field__label {
    color: 'primary-color';
    font-size: 0.75rem;
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  .form-field__input:focus + .form-field__label + .form-field__bar,
  .form-field__input:not(:placeholder-shown)
    + .form-field__label
    + .form-field__bar {
    border-bottom: 2px solid 'secondary-color';
    -webkit-transform: scaleX(100);
    transform: scaleX(100);
  }
  .form-field__select:focus + .form-field__label,
  .form-field__select:not(:placeholder-shown) + .form-field__label {
    color: 'primary-color';
    -webkit-transform: translateY(-7vh);
    transform: translateY(-7vh);
    font-size: 1rem;
  }
  .form-field__select:focus + .form-field__label + .form-field__bar,
  .form-field__select:not(:placeholder-shown)
    + .form-field__label
    + .form-field__bar {
    border-bottom: 2px solid 'secondary-color';
    -webkit-transform: scaleX(100);
    transform: scaleX(100);
  }
  .form-select {
    width: 70%;
    margin-left: 15%;
    margin-top: 24px;
    padding: 0;
  }
  .form-select-small {
    width: 70%;
    margin-top: 5px;
  }
  .form-switch {
    padding-left: 4vw;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  .mcc-verified {
    font-size: 0.75rem;
    text-align: right;
    padding-right: 20px;
  }
  .phone-input {
    padding: 10px 0;
  }
}

.form-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.form-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 30px 0 0;
}

.form-title h2 {
  padding: 0;
  font-weight: 700;
  font-size: 1.3rem;
}

.form-x-btn {
  position: relative;
  padding-right: 20px;
}

.form-x-btn.close {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.form-x-btn-line {
  width: 23px;
  height: 1px;
}

.theme--default .form-x-btn-line {
  background-color: #282828;
}

.theme--dark .form-x-btn-line {
  background-color: #fff;
}

.form-x-btn-line:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(0.5px, 0.5px);
  transform: rotate(45deg) translate(0.5px, 0.5px);
}

.form-x-btn-line:nth-child(2) {
  -webkit-transform: rotate(-45deg) translate(0.5px, -0.5px);
  transform: rotate(-45deg) translate(0.5px, -0.5px);
}

.form-nav-bottom {
  width: 50%;
  text-align: center;
  margin: 2vh 0;
}

.form-submit-btn {
  width: 50%;
  text-align: center;
  margin-top: 3vh;
}

.form-continue-btn {
  text-decoration: underline;
  font-weight: 700;
  margin-left: 10vw;
}

.form-responsive-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-select {
  width: 90%;
  padding: 0 6px;
  font-size: 0.875rem;
}

.form-select-small {
  width: 70%;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .form-select {
    width: 70%;
    margin-left: 15%;
    margin-top: 24px;
    padding: 0;
  }
  .form-select-small {
    width: 100%;
    margin-top: 5px;
  }
}

.form-field__control {
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0px;
  -webkit-tap-highlight-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.35;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(22, 22, 51, 0.145);
  -o-border-image: initial;
  border-image: initial;
  overflow: hidden;
  -webkit-transition: border-color 0.2s ease 0s;
  transition: border-color 0.2s ease 0s;
}

.form-field__label {
  display: block;
  font-size: 1.2rem;
  font-weight: normal;
  left: 0;
  margin: 0;
  padding: 18px 0 0 12px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
}

.form-field__label-select {
  display: block;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  padding: 0 0 6px 12px;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
  text-align: left;
}

.form-field__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #999;
  color: #333;
  font-size: 1.2rem;
  margin-top: 24px;
  outline: 0;
  padding: 5px 0 5px 16px;
  width: 100%;
  overflow: hidden;
}

.form-field__input[disabled] {
  color: #dcdad1;
}

.form-field__input-tip {
  font-size: 12px;
  color: #637381;
  padding: 3px 0 0 10px;
}

.form-field__bar {
  border-bottom: 2px solid 'primary-color';
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 1%;
}

.form-field__switch {
  margin-left: 8%;
  margin-top: 24px;
}

.form-field__switch-small {
  margin: 10px 0 0 20px;
}

.form-field__select {
  margin-top: 10px;
}

.form-field__select-small {
  margin-top: 10px;
}

.form-field__input:focus + .form-field__label,
.form-field__input:not(:placeholder-shown) + .form-field__label {
  color: 'primary-color';
  font-size: 0.75rem;
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px);
}

.form-field__input:focus + .form-field__label + .form-field__bar,
.form-field__input:not(:placeholder-shown)
  + .form-field__label
  + .form-field__bar {
  border-bottom: 2px solid #52edff;
  -webkit-transform: scaleX(100);
  transform: scaleX(100);
}

.form-field__select:focus + .form-field__label,
.form-field__select:not(:placeholder-shown) + .form-field__label {
  color: 'primary-color';
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  font-size: 1rem;
}

.form-container-singleview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fbfafa;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px;
  margin-top: 20px;
}

.singleview-label {
  margin: 0 40px 0 0;
  font-size: 18px;
}

.singleview-submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.plain-form-container {
  position: relative;
  top: 12vh;
  left: 20%;
}

.plain-form-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5vw;
}

.plain-form-column-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 3vw;
  text-align: right;
}

.plain-form-column-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 3vw;
  text-align: left;
}

.plain-form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 2vh;
}

.plain-form-input {
  border: 1px solid #999;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 1.2rem;
  margin: 2vh 0 0.5vh 0;
  outline: 0;
  padding: 5px 12px 5px 16px;
  width: 100%;
  overflow: hidden;
}

.plain-form-label {
  font-size: 1rem;
}

.plain-form-text {
  font-size: 1.2rem;
  margin: 2vh 0;
}

.plain-form-nav-btn {
  text-align: center;
}

.form-container {
  margin: 0;
  width: 100%;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-container-item {
  margin: 0;
  margin-top: 5vh;
  text-align: left;
}

.form-container-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 6px 0 12px;
}

.form-container-row-responsive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  margin: 6px 0 12px;
}

.form-container-row p {
  min-width: 55%;
  max-width: 55%;
  font-size: 14px;
  padding: 0 0 0 16px;
}

@media (min-width: 769px) {
  .form-container-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 5vh;
  }
  .form-container-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-container-row-responsive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-container-row p {
    min-width: 70%;
  }
}

.form-field {
  display: block;
  margin: 3vh 0;
  width: 100%;
}

@media (min-width: 769px) {
  .form-field {
    width: 80%;
  }
}

.form-field-small {
  display: block;
  margin: 0 0 5px 0;
  text-align: left;
  width: 100%;
}

@media (min-width: 769px) {
  .form-field-small {
    width: 80%;
  }
}

.form-field-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  padding: 6px 0;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
}

.form-field-select-label {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2rem;
  padding: 6px 12px 6px 0;
  text-align: left;
}

.form-field__switch {
  text-align: center;
  margin: 0;
  padding: 2vw;
}

.form-field__switch p {
  padding: 2vh;
}

.form-field__select {
  margin-top: 10px;
}

.form-field__select-small {
  margin-top: 20px;
}

.auto-approve-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
  padding: 2vh 0;
  color: #29cc97;
  font-weight: 700;
  cursor: pointer;
}

.auto-approve-btn:hover {
  color: #77e4c0;
}

.delegate-purchase-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: left;
  align-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  padding: 2vh 0;
  color: #0173bc;
  font-weight: 700;
  cursor: pointer;
}

.delegate-purchase-btn:hover {
  color: #25a9fe;
}

.delegate-purchase-cancel-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: right;
  align-content: right;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding: 1vh 0;
  color: #fd4d4d;
  font-weight: 700;
  cursor: pointer;
}

.delegated-summary {
  color: #0173bc;
  font-weight: bold;
}

.new-feature {
  color: #fec810;
  font-size: 14px;
  padding-left: 5px;
  text-align: center;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 12px 0;
  width: 80%;
}

.form-row-item {
  width: 100%;
}

@media (min-width: 769px) {
  .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.form-main-addform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10vh;
}

@media (min-width: 1025px) {
  .form-main-addform {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
  }
}

.form-title-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  width: 80%;
  padding: 20px 0 6px;
}

@media (min-width: 769px) {
  .form-title-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
    margin: 15px 0 0;
    padding: 20px 0;
  }
}

.form-link-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
  margin: 0;
  padding: 0;
}

.form-title-user-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.form-title-user-title h2 {
  padding: 0;
  font-weight: 700;
  font-size: 1.3rem;
}

.autobudget-cardholder-title {
  padding-top: 3px;
}

.form-submit-btn-user {
  margin: 2vh 0 0;
  padding: 0;
}

@media (min-width: 769px) {
  .form-submit-btn-user {
    margin: 0;
  }
}

.form-container-row-addform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 6px 12px 12px;
}

.form-container-row-addform-responsive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 6px 12px 12px;
}

.form-container-row-addform-text {
  text-align: left;
  font-size: 1.2rem;
  padding: 16px 0;
}

@media (min-width: 769px) {
  .form-container-row-addform {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    width: 90%;
  }
}

@media (min-width: 769px) and (min-width: 1025px) {
  .form-container-row-addform {
    width: 80%;
  }
}

@media (min-width: 769px) {
  .form-container-row-addform-responsive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 90%;
  }
}

@media (min-width: 769px) and (min-width: 1025px) {
  .form-container-row-addform-responsive {
    width: 80%;
  }
}

@media (min-width: 769px) {
  .form-container-row-addform-text {
    min-width: 65%;
    text-align: left;
    font-size: 1.2rem;
  }
}

@media (min-width: 769px) and (min-width: 1025px) {
  .form-container-row-addform-text {
    min-width: 70%;
  }
}

.addform-input-small {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #999;
  color: #333;
  display: block;
  font-size: 0.875rem;
  margin: 0 0 0 20px;
  outline: 0;
  padding: 8px 10px;
  overflow: hidden;
}

@media screen and (min-width: 414px) and (max-width: 769px) {
  .addform-input-small {
    width: 70%;
  }
}

.addform-maxwidth {
  max-width: 1000px;
}

.formik-form-select {
  font-family: 'Muli', sans-serif;
  border-radius: 4px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(22, 22, 51, 0.145);
  -o-border-image: initial;
  border-image: initial;
  overflow: hidden;
  -webkit-transition: border-color 0.2s ease 0s;
  transition: border-color 0.2s ease 0s;
  width: 100%;
  padding: 10px 6px;
  font-size: 0.875rem;
}

.formik-input-mtop {
  margin-top: 1rem;
}

@media (min-width: 769px) {
  .formik-max-width {
    max-width: 700px;
  }
}

@media screen and (max-width: 768px) {
  .formik-max-width {
    margin-left: 10%;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .formik-step-mleft {
    margin-left: 10%;
  }
}

.online-purchase-heading {
  font-size: 1.25rem;
  margin: 1rem 1rem 1rem 0;
}

@media screen and (max-width: 768px) {
  .online-purchase-heading {
    margin: 2rem auto;
  }
}

.online-purchase-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .online-purchase-btn-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -ms-flex-line-pack: center;
    align-content: center;
  }
}

.online-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  -webkit-box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
}

@media screen and (max-width: 768px) {
  .online-purchase-btn {
    margin-bottom: 2.5rem;
    padding: 1.25rem 2.5rem;
    font-size: 1.5rem;
  }
}

.btn-selected-value {
  border-width: 2px;
  font-weight: bold;
}

.btn-select {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 1.8rem;
  font-size: 1.1rem;
  -webkit-box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
}

@media screen and (max-width: 768px) {
  .btn-select {
    margin: 1rem 1rem 2rem 0;
    padding: 1.2rem 2rem 1.2rem 1.2rem;
    font-size: 1.2rem;
    width: 125px;
  }
}

.or-text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80%;
  margin: 1rem 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .or-text-container {
    width: 100%;
    margin: 2vh auto;
  }
}

.or-text {
  margin: 0 1rem;
}

.category-purchase-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .category-purchase-btn-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.category-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  -webkit-box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
}

@media screen and (max-width: 768px) {
  .category-purchase-btn {
    margin: 1rem 0 2.5rem 0;
    padding: 1.1rem 2rem;
    font-size: 1rem;
  }
}

.amount-purchase-btn {
  margin: 1rem 2rem 2.5rem 0;
  padding: 0.875rem 2rem;
  font-size: 1.1rem;
  -webkit-box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
  box-shadow: 0 3px 6px rgba(34, 37, 37, 0.15);
}

@media screen and (max-width: 768px) {
  .amount-purchase-btn {
    margin: 1.5rem 0 1.5rem 0;
    padding: 1.1rem 1.2rem;
    font-size: 1.1rem;
  }
}

.amount-input-width {
  max-width: 200px;
}

@media screen and (max-width: 768px) {
  .amount-input-width {
    max-width: none;
    margin-right: 2rem;
  }
}
/* ------------------------------ */
/* ! description editor section ! */
/* ------------------------------ */

.description-editor {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: text;
  min-height: 200px;
  margin-bottom: 15px;
  padding: 10px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  /* display: block;
  position: relative; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  font-family: 'Muli', sans-serif;
  line-height: 1.5em;
  width: 100%;
  letter-spacing: normal;
  font-size: 18px;
}

@media (min-width: 769px) {
  .description-editor {
    border: 1px solid #ccc;
    cursor: text;
    min-height: 80px;
    padding: 30px;
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    display: block;
    position: relative;
    border-radius: 4px;
  }
}

.description-editor-container {
  padding: 10px 20px;
  text-align: center;
}

@media (min-width: 769px) {
  .description-editor-container {
    padding: 20px;
    width: 700px;
  }
}

.description-width {
  max-width: 130px;
  max-height: 20px;
  overflow-y: hidden;
}

@media (min-width: 769px) {
  .description-width {
    max-width: none;
  }
}

.form-switch {
  padding-left: 2vw;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.form-switch-small {
  padding-left: 20px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.label-small {
  font-size: 0.875rem;
  padding: 5px 12px -2px 16px;
}

.input-small {
  width: 30%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #999;
  color: #333;
  display: block;
  font-size: 0.875rem;
  margin: 0 0 0 20px;
  outline: 0;
  padding: 8px 10px;
  overflow: hidden;
}

.mcc-verified {
  font-size: 0.75rem;
  text-align: right;
  padding-left: 10px;
}

.phone-input {
  padding: 10px 0;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .phone-input {
    width: 100%;
  }
}

.current-role-select-role {
  font-size: 0.875rem;
}

@media (max-width: 320px) {
  .current-role-select-role {
    width: 150px;
  }
}

@media (max-width: 320px) {
  .form-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;
  }
  .form-title h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
  .form-x-btn {
    padding-right: 15px;
  }
  .form-nav-bottom {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    top: auto;
    padding: 0;
    margin: 5vh 0;
  }
  .form-submit-btn {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    top: auto;
    margin: 5vh 0 0 0;
    padding: 0;
    text-decoration: underline;
  }
}

@media (max-width: 360px) {
  .form-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: 30px 0 0;
  }
  .form-title h2 {
    padding: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
  .form-x-btn {
    padding-right: 15px;
  }
  .form-nav-bottom {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    top: auto;
    padding: 0;
    margin: 5vh 0;
  }
  .form-submit-btn {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    top: auto;
    margin: 5vh 0 0 0;
    padding: 0;
    text-decoration: underline;
  }
}

@media (max-width: 411px) {
  .form-submit-btn {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    top: auto;
    margin: 5vh 0 0 0;
    padding: 0;
    text-decoration: underline;
  }
}

.approval-circle-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.card-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  width: 80%;
}

.card-list-item-cardnumber {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
}

.card-list-item-cardimage {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-list-item-cardstatus {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  padding: 0 10px;
  /* font-weight: bold; */
}

.card-list-item-cardstatus-approval {
  color: #fec810;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  padding: 5px;
}

.card-list-item-cardstatus-active {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
}

.card-list-item-cardstatus-inactive {
  color: #fd4d4d;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  /* font-weight: bold; */
}

.card-list-item-statustext {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 0 10px;
  font-weight: bold;
}

.card-list-item-holdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.card-list-item-unholdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17%;
  flex: 0 0 17%;
}

.card-list-item-deletebtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}

.card-list-item-btngroup {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.card-list {
  margin-top: 15px;
}

.trash-icon {
  padding: 1px;
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .card-list-item {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'cardnumber cardnumber cardnumber'
      'cardstatus statustext statustext'
      'holdbtn unholdbtn deletebtn';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
  .card-list-item-cardnumber {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: cardnumber;
    padding: 0 5px 0 0;
    font-size: 16px;
    overflow: hidden;
    min-width: 100%;
    max-height: 25px;
  }
  .card-list-item-cardstatus {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: cardstatus;
    padding: 0;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    max-width: 20px;
  }
  .card-list-item-statustext {
    padding: 0;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: statustext;
    text-align: left;
    font-size: 14px;
  }
  .card-list-item-holdbtn {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: holdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .card-list-item-holdbtn span {
    display: none;
  }
  .card-list-item-unholdbtn {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: unholdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .card-list-item-unholdbtn span {
    display: none;
  }
  .card-list-item-deletebtn {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: deletebtn;
    padding: 10px 0 0 0;
  }
  .card-list-item-cardimage {
    display: none;
  }
}

@media (max-width: 320px) {
  .card-list-item {
    min-width: 320px;
  }
}

.cardpage-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.cardpage-list-item:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.cardpage-list-item-cardnumber {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
}

.cardpage-list-item-cardimage {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cardpage-list-item-cardholdername {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.cardpage-list-item-purchaselimit {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  padding: 10px 0 10px 10px;
}

.cardpage-list-item-cardstatus {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  padding: 0 10px;
  /* font-weight: bold; */
}

.cardpage-list-item-cardstatus-approval {
  color: #fec810;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  padding: 5px;
}

.cardpage-list-item-cardstatus-active {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
}

.cardpage-list-item-cardstatus-inactive {
  color: #fd4d4d;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  /* font-weight: bold; */
}

.cardpage-list-item-autobudgets {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 0 10px;
  font-weight: 600;
}

.cardpage-list-item-holdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.cardpage-list-item-unholdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17%;
  flex: 0 0 17%;
}

.cardpage-list-item-deletebtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}

.cardpage-list-item-btngroup {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.card-list {
  margin-top: 15px;
}

.trash-icon {
  padding: 1px;
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .cardpage-list-item {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'cardholdername cardholdername'
      'autobudgets   drop';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
  .cardpage-list-item-cardholdername {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cardholdername;
    padding: 0 10px 0 0;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: none;
    word-wrap: break-word;
    height: 25px;
  }
  .cardpage-list-item-purchaselimit {
    color: #282828;
    font-size: 18px;
    font-weight: 600;
    text-align: right;
  }
  .cardpage-list-item-cardnumber {
    color: gray;
    padding: 0 5px 0 10px;
    font-size: 14px;
    overflow: hidden;
    min-width: 100%;
    max-height: 25px;
  }
  .cardpage-list-item-cardstatus {
    grid-area: cardstatus;
    padding: 0;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    max-width: 20px;
  }
  .cardpage-list-item-autobudgets {
    padding: 0;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: autobudgets;
    text-align: left;
    font-size: 16px;
  }
  .cardpage-list-item-holdbtn {
    grid-area: holdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .cardpage-list-item-holdbtn span {
    display: none;
  }
  .cardpage-list-item-unholdbtn {
    grid-area: unholdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .cardpage-list-item-unholdbtn span {
    display: none;
  }
  .cardpage-list-item-deletebtn {
    grid-area: deletebtn;
    padding: 10px 0 0 0;
  }
  .cardpage-list-item-cardimage {
    display: none;
  }
  .cardpage-list-item-drop {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-area: drop;
    text-align: right;
  }
}

@media (max-width: 320px) {
  .cardpage-list-item {
    min-width: 320px;
  }
}

.yourcard-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.yourcard-list-item:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.yourcard-list-item-cardnumber {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 10px 10px 10px;
  overflow-x: hidden;
}

.yourcard-list-item-cardimage {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yourcard-list-item-cardholdername {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.yourcard-list-item-purchaselimit {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  padding: 10px 0 10px 10px;
}

.yourcard-list-item-cardstatus {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  padding: 0 10px;
  /* font-weight: bold; */
}

.yourcard-list-item-cardstatus-approval {
  color: #fec810;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  padding: 5px;
}

.yourcard-list-item-cardstatus-active {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
}

.yourcard-list-item-cardstatus-inactive {
  color: #fd4d4d;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  /* font-weight: bold; */
}

.yourcard-list-item-autobudgets {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 0 10px;
  font-weight: 600;
}

.yourcard-list-item-holdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.yourcard-list-item-unholdbtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17%;
  flex: 0 0 17%;
}

.yourcard-list-item-deletebtn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}

.yourcard-list-item-btngroup {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.card-list {
  margin-top: 15px;
}

.trash-icon {
  padding: 1px;
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .yourcard-list-item {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'cardholdername cardholdername'
      'autobudgets   drop';
    grid-gap: 1px;
    border: none;
    margin: 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
  .yourcard-list-item-cardholdername {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: cardholdername;
    padding: 0 10px 0 0;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: none;
    word-wrap: break-word;
    height: 25px;
  }
  .yourcard-list-item-purchaselimit {
    color: #282828;
    font-size: 18px;
    font-weight: 600;
    text-align: right;
  }
  .yourcard-list-item-cardnumber {
    color: gray;
    padding: 0 5px 0 10px;
    font-size: 14px;
    overflow: hidden;
    min-width: 100%;
    max-height: 25px;
  }
  .yourcard-list-item-cardstatus {
    grid-area: cardstatus;
    padding: 0;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    max-width: 20px;
  }
  .yourcard-list-item-autobudgets {
    padding: 0;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: autobudgets;
    text-align: left;
    font-size: 16px;
  }
  .yourcard-list-item-holdbtn {
    grid-area: holdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .yourcard-list-item-holdbtn span {
    display: none;
  }
  .yourcard-list-item-unholdbtn {
    grid-area: unholdbtn;
    padding: 10px 0 0 0;
    font-size: 12px;
    overflow: hidden;
  }
  .yourcard-list-item-unholdbtn span {
    display: none;
  }
  .yourcard-list-item-deletebtn {
    grid-area: deletebtn;
    padding: 10px 0 0 0;
  }
  .yourcard-list-item-cardimage {
    display: none;
  }
  .yourcard-list-item-drop {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-area: drop;
    text-align: right;
  }
}

@media (max-width: 320px) {
  .yourcard-list-item {
    min-width: 320px;
  }
}

.cardpage-autobudget-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  padding-left: 10%;
}

.cardpage-autobudget-list-item:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.cardpage-autobudget-list-item-categoryname {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.cardpage-autobudget-list-item-budgetname {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  padding: 0 10px;
  font-weight: 600;
}

.cardpage-autobudget-list-item-remainingbudget {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  padding: 0 10px;
  font-weight: bold;
}

.cardpage-autobudget-list-item-remainingbudget-text {
  padding: none;
  color: rgba(54, 55, 64, 0.5);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.cardpage-autobudget-list-item-remainingbudget-approval {
  color: #fec810;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  padding: 5px;
}

.cardpage-autobudget-list-item-remainingbudget-active {
  color: #29cc97;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
}

.cardpage-autobudget-list-item-remainingbudget-inactive {
  color: #fd4d4d;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
  /* font-weight: bold; */
}

.card-autobudget-list {
  margin-bottom: 20px;
}

.card-list-item-autobudget-empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .card-list-item-autobudget-empty {
    margin: 0 0 20px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
}

@media (max-width: 320px) {
  .card-list-item-autobudget-empty {
    min-width: 320px;
  }
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .cardpage-autobudget-list-item {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'categoryname remainingbudget'
      'budgetname remainingbudget';
    grid-gap: 1px;
    border: none;
    margin: 0 0 20px 0;
    padding: 0 19px 0 43px;
    min-width: 375px;
  }
  .cardpage-autobudget-list-item-categoryname {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: categoryname;
    padding: 0 10px 0 0;
    font-size: 18px;
    font-weight: 600;
    overflow-x: hidden;
    overflow-y: none;
    word-wrap: break-word;
    height: 25px;
  }
  .cardpage-autobudget-list-item-cardnumber {
    color: gray;
    padding: 0 5px 0 10px;
    font-size: 14px;
    overflow: hidden;
    min-width: 100%;
    max-height: 25px;
  }
  .cardpage-autobudget-list-item-cardstatus {
    grid-area: cardstatus;
    padding: 0;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    max-width: 20px;
  }
  .cardpage-autobudget-list-item-budgetname {
    padding: 0;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: budgetname;
    text-align: left;
    font-size: 14px;
    color: #59a1c9;
  }
  .cardpage-autobudget-list-item-remainingbudget {
    text-align: right;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 2;
    grid-area: remainingbudget;
    font-weight: bold;
    font-size: 18px;
    color: #29cc97;
    padding: none;
  }
  .cardpage-autobudget-list-item-remainingbudget-text {
    padding: none;
    color: rgba(54, 55, 64, 0.5);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .cardpage-autobudget-list-item {
    min-width: 320px;
  }
}

.users-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.users-list-item:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.users-list-item-circle-img {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 30px;
  height: 30px;
  margin-left: 30px;
}

.users-list-item-username {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.users-list-item-userstatus {
  padding: 0 10px 0 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.users-list-item-userrolename {
  font-size: 12px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  height: 15px;
  overflow: hidden;
}

.users-list-item-usertype {
  font-size: 12px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  height: 15px;
  overflow: hidden;
}

.users-list-item-email {
  font-size: 12px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  height: 15px;
  overflow: hidden;
}

@media (max-width: 1025px) {
  .users-list-item-email {
    display: none;
  }
}

.users-list-item-phone {
  font-size: 12px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  height: 15px;
  overflow: hidden;
}

@media (max-width: 1025px) {
  .users-list-item-phone {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .users-list-item {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
    'img username userstatus card'
    'img userrolename usertype usertype';
    grid-gap: 1px;
    border-bottom: 1px solid #dfe0eb;
    margin: 0;
    padding: 20px 10px;
    min-width: 325px;
  }
  .users-list-item-circle-img {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    grid-area: img;
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 36px;
    height: 36px;
    margin-left: 0;
  }
  .users-list-item-username {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: username;
    font-size: 16px;
    padding: 0;
    overflow-x: hidden;
  }
  .users-list-item-userstatus {
    /* display: none; */
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: userstatus;
    font-size: 12px;
    overflow: hidden;
  }
  .users-list-item-userrolename {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-area: userrolename;
    font-size: 12px;
    color: #637381;
    overflow: hidden;
    width: 145px;
  }
  .users-list-item-userType {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
    grid-area: userType;
    font-size: 12px;
    color: #637381;
    overflow: hidden;
  }
  .users-list-item-email {
    grid-area: email;
    font-size: 12px;
    color: #637381;
    overflow: hidden;
  }
  .users-list-item-phone {
    grid-area: phone;
    font-size: 12px;
    color: #637381;
    overflow: hidden;
  }
}

.list-item-budgettran {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.list-item-budgettran:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.list-item-budgettran-avatar-pic {
  border-radius: 50%;
  border: 2px solid #dfe0eb;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.list-item-budgettran-purchaser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-item-budgettran-name {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  padding: 10px 0 10px 43px;
  overflow-x: hidden;
}

.list-item-budgettran-namebudgettran {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.list-item-budgettran-status {
  padding: 0 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}

.list-item-budgettran-action {
  padding: 5px 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  font-weight: bold;
  color: #e4572e;
  border-radius: 5px;
}

.list-item-budgettran-amount {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  font-weight: bold;
  text-align: right;
  padding-right: 5%;
}

.list-item-budgettran-role {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13%;
  flex: 0 0 13%;
  font-weight: bold;
}

.list-item-budgettran-description {
  font-size: 14px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  height: 15px;
  overflow: hidden;
}

.list-item-budgettran-email {
  font-size: 12px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  height: 15px;
  overflow: hidden;
}

.list-item-budgettran-date {
  font-size: 14px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5%;
  flex: 0 0 5%;
}

.list-item-budgettran-type {
  font-size: 14px;
  color: #637381;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}

.list-item-budgettran-delete {
  font-size: 14px;
  color: #637381;
  text-align: right;
  -webkit-box-flex: 0;
  -ms-flex: 0 100%;
  flex: 0 100%;
}

@media screen and (max-width: 768px) {
  /* Designate Grid Items */
  .list-item-budgettran {
    /* Set Up Grid */
    display: -ms-grid;
    display: grid;
    grid-template-areas:
      'purchaser purchaser date'
      'namebudgettran namebudgettran amount'
      'description status status ';
    grid-gap: 1px;
    border: none;
    margin: 0 0 25px 0;
    padding: 0 19px 0 23px;
    min-width: 375px;
  }
  .list-item-budgettran-avatar-pic {
    border-radius: 50%;
    border: 1.5px solid #dfe0eb;
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
  .list-item-budgettran-purchaser {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: purchaser;
    padding: 0 0 5px 5px;
    font-size: 14px;
    overflow: hidden;
  }
  .list-item-budgettran-namebudgettran {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: namebudgettran;
    padding: 0 10px 0 0;
    font-size: 18px;
    overflow: hidden;
    word-wrap: break-word;
    width: 250px;
    height: 25px;
  }
  .list-item-budgettran-status {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: status;
    padding: 0;
    font-size: 14px;
    text-align: right;
    overflow: hidden;
  }
  .list-item-budgettran-action {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: status;
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    -ms-grid-column-align: end;
    justify-self: end;
    width: 150px;
    padding: 5px 8px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    font-size: 0.75rem;
    font-weight: bold;
    color: #e4572e;
    text-align: center;
    border: 1px solid #e4572e;
    border-radius: 5px;
  }
  .list-item-budgettran-action {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-area: status;
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    -ms-grid-column-align: end;
    justify-self: end;
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    font-weight: bold;
    color: #e4572e;
    border-radius: 5px;
  }
  .list-item-budgettran-amount {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
    grid-area: amount;
    text-align: right;
    font-size: 18px;
    padding: 0;
  }
  .list-item-budgettran-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: description;
    font-size: 14px;
    overflow: hidden;
    width: 100px;
  }
  .list-item-budgettran-date {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: date;
    font-size: 14px;
    text-align: right;
  }
}

@media (max-width: 320px) {
  .list-item-budgettran {
    min-width: 320px;
  }
  .list-item-budgettran-namebudgettran {
    width: 195px;
  }
  .list-item-budgettran-description {
    width: 60px;
  }
}

.role-list-item {
  display: block;
  padding: 10px;
}

.portal-header-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 20px 0 5px 0;
  border-bottom: 3px solid #e8e9e9;
}

.portal-header-tab {
  display: inline-block;
  background-color: #fff;
  color: #babdbe;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: -3px;
}

.portal-tab {
  border-bottom: 3px solid #e8e9e9;
  padding: 8px 24px 8px 24px;
  display: block;
  color: inherit;
  text-decoration: inherit;
  white-space: nowrap;
}

.portal-tab-active {
  background-color: #fff;
  border-bottom: 3px solid #e4572e;
  color: #282828;
}

.portal-page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px 0;
}

.addcompany-submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.addcompany-btn {
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  background-color: #e4572e;
  border-radius: 4px;
  border: 1px solid #c5401a;
  font-size: 16px;
  padding: 10px 30px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: -webkit-transform 150ms, -webkit-box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms;
  transition: transform 150ms, box-shadow 150ms, -webkit-transform 150ms,
    -webkit-box-shadow 150ms;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e4572e),
    to(#dc481c)
  );
  background-image: linear-gradient(-180deg, #e4572e 0, #dc481c 100%);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 768px) {
  .portal-header-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 20px 0 5px 0;
    border-bottom: 3px solid #e8e9e9;
  }
  .portal-header-tab {
    display: inline-block;
    background-color: #fff;
    color: #babdbe;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: -3px;
  }
}

.portal-page-container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px 40px;
  display: block;
}

.theme--default .portal-page-container {
  background-color: white;
}

.theme--dark .portal-page-container {
  background-color: #282828;
}

@media screen and (min-width: 769px) and (max-width: 1170px) {
  .portal-page-container {
    padding: 50px 10px;
  }
}

.portal-company-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  padding-right: 5px;
  width: 280px;
  border-top: 1px solid #d8d9dd;
  border-right: 1px solid #d8d9dd;
}

.company-listitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-weight: 400;
}

.company-listitem:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.company-listitem-name {
  padding: 10px 0 10px 43px;
}

.portal-company-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.portal-company-searchbox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f4f6f6;
  color: #babdbe;
  padding: 7px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.portal-company-searchinput {
  border: none;
  background: #f4f6f6;
  color: #818487;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
}

.portal-company-detail {
  border-top: 1px solid #d8d9dd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.portal-company-header {
  padding-left: 40px;
  border-bottom: 1px solid #d8d9dd;
}

.portal-company-detail-title {
  margin: 23px 0 0 0;
}

.portal-company-statsrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 2.5;
}

.portal-company-detailitem-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  margin: 0 10px;
}

.portal-company-detailitem-column-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
}

.portal-detail-item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.portal-detail-entry {
  font-size: 14px;
  color: 'primary-color';
  margin-top: 10px;
  line-height: 1.5;
}

.portal-detail-label {
  font-size: 14px;
  color: #637381;
  margin-top: 10px;
  line-height: 1.5;
}

.input-addcompany {
  font-size: 16px;
  padding: 20px 12px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}

.selectinput-addcompany {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}

.phoneinput-addcompany {
  font-size: 16px;
  padding: 20px 12px;
  display: block;
  width: 100%;
  height: 34px;
  color: #282828;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 8px;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 34px !important;
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.formrow-addcompany {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 20px;
}

.formlayout-addcompany {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 20px 0;
}

.formcolumn-addcompany {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 20px;
  width: 100%;
}
