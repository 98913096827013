.portal-page-container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px 40px;
  display: block;
  @include themed() {
    background-color: t($bg);
  }
}
@include mediaLg {
  .portal-page-container {
    padding: 50px 10px;
  }
}
.portal-company-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding-right: 5px;
  width: 280px;
  border-top: 1px solid #d8d9dd;
  border-right: 1px solid #d8d9dd;
}

.company-listitem {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
  border-bottom: 1px solid #d8d9dd;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }
}

.company-listitem-name {
  padding: 10px 0 10px 43px;
}

.portal-company-layout {
  display: flex;
  flex-direction: row;
}

.portal-company-searchbox {
  width: 100%;
  display: flex;
  background: #f4f6f6;
  color: #babdbe;
  padding: 7px 10px;
  align-items: center;
  margin-bottom: 10px;
}

.portal-company-searchinput {
  border: none;
  background: #f4f6f6;
  color: #818487;
  flex-grow: 1;
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
}
