.grid-label {
  display: flex;

  // Position Items
  justify-content: flex-end; // horizontal alignment
  align-content: center; // vertical alignment
  text-align: right;

  // Item Attributes
  white-space: nowrap;

  // Style Items
  font-size: 0.8rem;
  color: #637381;

  // * Use this for text overflow
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // line-height: 1rem;
  // max-height: 2rem;
  // -webkit-line-clamp: 1; /* Write the number of
  //                               lines you want to be
  //                               displayed */
  // -webkit-box-orient: vertical;

  @include mediaMd {
    // Item Attributes
    white-space: pre-line;
  }
}
.grid-value {
  display: flex;

  // Position Items
  align-content: center; // vertical alignment
  justify-content: flex-start; // horizontal alignment

  // Item Attributes
  white-space: pre-line;

  // Style Items
  font-size: 0.9rem;

  // * Use this for text overflow
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // line-height: 1rem;
  // max-height: 2rem;
  // -webkit-line-clamp: 1; /* Write the number of
  //                               lines you want to be
  //                               displayed */
  // -webkit-box-orient: vertical;
}
.new-people {
  &-section {
    &-budget {
      width: 100%;
      // overflow-x: scroll;
      // list-style: none;

      margin: 1em 1em 2em 1em;

      @include mediaMd {
        margin: 1em 0 2em 0;
      }
    }
  }
}
.new-detail {
  &-section-transaction {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      'merchantNameLabel         merchantName     blank1  budgetLabel      budget   '
      'merchantAddressLabel      merchantAddress  blank1  costCodeLabel    costCode '
      'descriptionLabel          description      blank1  tagsLabel        tags  '
      'amountLabel               amount           blank1  blank2           tags '
      'dateCreatedLabel          dateCreated      blank1  datePostedLabel  datePosted '
      'purchaserLabel            purchaser        blank1  approverLabel    approver';

    gap: 0.5em;
    row-gap: 1.5em;
    padding: 0 1.25em;
    margin: 1em 0 1.25em 0.5em;

    place-self: flex-start;

    @include mediaMd {
      grid-template-columns: auto auto;
      grid-template-areas:
        'merchantNameLabel        merchantName'
        'merchantAddressLabel     merchantAddress'
        'descriptionLabel         description'
        'amountLabel              amount'
        'dateCreatedLabel         dateCreated'
        'purchaserLabel           purchaser'
        'budgetLabel              budget'
        'costCodeLabel            costCode'
        'tagsLabel                tags'
        'datePostedLabel          datePosted'
        'approverLabel            approver';

      padding: 0 0.5em;
    }

    &-merchantName-label {
      grid-area: merchantNameLabel;
    }
    &-merchantName-value {
      grid-area: merchantName;

      // Item Attributes
      white-space: pre-line;
    }
    &-merchantAddress-label {
      grid-area: merchantAddressLabel;
    }
    &-merchantAddress-value {
      grid-area: merchantAddress;

      display: flex;
      flex-direction: column;

      gap: 0.25em;
    }
    &-description-label {
      grid-area: descriptionLabel;
    }
    &-description-value {
      grid-area: description;

      // Item Attributes
      white-space: pre-line;
    }
    &-amount-label {
      grid-area: amountLabel;
    }
    &-amount-value {
      grid-area: amount;

      // Item Attributes
      white-space: nowrap;
    }
    &-dateCreated-label {
      grid-area: dateCreatedLabel;
    }
    &-dateCreated-value {
      grid-area: dateCreated;

      // Item Attributes
      white-space: nowrap;
    }
    &-purchaser-label {
      grid-area: purchaserLabel;
    }
    &-purchaser-value {
      grid-area: purchaser;

      // Item Attributes
      white-space: nowrap;
    }
    &-budget-label {
      grid-area: budgetLabel;
    }
    &-budget-value {
      grid-area: budget;

      // Item Attributes
      white-space: pre-line;
    }
    &-costCode-label {
      grid-area: costCodeLabel;
    }
    &-costCode-value {
      grid-area: costCode;

      // Item Attributes
      white-space: nowrap;
    }
    &-tags-label {
      grid-area: tagsLabel;
    }
    &-tags-value {
      grid-area: tags;

      // Item Attributes
      white-space: pre-line;

      display: flex;
      flex-direction: column;

      gap: 0.25em;
    }
    &-datePosted-label {
      grid-area: datePostedLabel;
    }
    &-datePosted-value {
      grid-area: datePosted;

      // Item Attributes
      white-space: nowrap;
    }
    &-approver-label {
      grid-area: approverLabel;
    }
    &-approver-value {
      grid-area: approver;

      // Item Attributes
      white-space: nowrap;
    }
  }

  &-section-budget {
    display: grid;
    // grid-template-columns: 1fr 1fr auto 0.8fr auto auto 0.8fr 1.5fr;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    grid-template-areas:
      'createdOnLabel        createdOn        blank1  tagsLabel         tags         tags         workflowLabel    workflow'
      'lastUpdatedLabel      lastUpdated      blank1  blank2            tags         tags         costCodesLabel   costCodes'
      'refreshIntervalLabel  refreshInterval  blank1  descriptionLabel  description  description  costCodesLabel   costCodes'
      'nextRefreshLabel      nextRefresh      blank1  budgetTypeLabel   budgetType   blank5       blank4           costCodes';

    gap: 0.5em;
    row-gap: 1.5em;
    padding: 0 1.25em;
    margin: 1em 0 1.25em 0.5em;

    place-self: flex-start;

    @include mediaMd {
      grid-template-columns: auto auto;
      grid-template-areas:
        'createdOnLabel        createdOn'
        'lastUpdatedLabel      lastUpdated'
        'refreshIntervalLabel  refreshInterval'
        'nextRefreshLabel      nextRefresh'
        'tagsLabel             tags'
        'descriptionLabel      description'
        'budgetTypeLabel       budgetType'
        'workflowLabel         workflow'
        'costCodesLabel        costCodes';

      padding: 0 0.5em;
    }

    &-created-label {
      grid-area: createdOnLabel;
    }
    &-created-value {
      grid-area: createdOn;

      // Item Attributes
      white-space: nowrap;
    }
    &-updated-label {
      grid-area: lastUpdatedLabel;
    }
    &-updated-value {
      grid-area: lastUpdated;

      // Item Attributes
      white-space: nowrap;
    }
    &-refreshInterval-label {
      grid-area: refreshIntervalLabel;
    }
    &-refreshInterval-value {
      grid-area: refreshInterval;

      // Item Attributes
      white-space: nowrap;
    }
    &-nextRefresh-label {
      grid-area: nextRefreshLabel;
    }
    &-nextRefresh-value {
      grid-area: nextRefresh;

      // Item Attributes
      white-space: nowrap;
    }
    &-tags-label {
      grid-area: tagsLabel;
    }
    &-tags-value {
      grid-area: tags;

      display: flex;
      flex-direction: column;

      gap: 0.25em;
    }
    &-tagA-label {
      grid-area: tagALabel;
    }
    &-tagA-value {
      grid-area: tagA;
    }
    &-tagB-label {
      grid-area: tagBLabel;
    }
    &-tagB-value {
      grid-area: tagB;
    }
    &-tagC-label {
      grid-area: tagCLabel;
    }
    &-tagC-value {
      grid-area: tagC;
    }
    &-description-label {
      grid-area: descriptionLabel;
    }
    &-description-value {
      grid-area: description;

      // Item Attributes
      white-space: pre-line;
    }
    &-budgetType-label {
      grid-area: budgetTypeLabel;
    }
    &-budgetType-value {
      grid-area: budgetType;
    }
    &-costCodes-label {
      grid-area: costCodesLabel;
    }
    &-costCodes-value {
      grid-area: costCodes;

      display: flex;

      gap: 0.25em;

      font-size: 0.75em;

      // Item Attributes
      white-space: nowrap;
    }
    &-workflow-label {
      grid-area: workflowLabel;
    }
    &-workflow-value {
      grid-area: workflow;
    }
  }
}

.detail {
  &-section {
    padding: 0 20px;
    text-align: left;
    margin-bottom: 15px;
    width: 80%;

    &-title {
      font-weight: 700;
      padding: 15px 23px 5px 43px;
      font-size: 14px;
      // position: relative;
      // margin: 20px 45% 20px 0;
      text-align: left;

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        // margin: 15px 0 0;
        padding: 20px 0;
      }
      &-status {
        font-weight: 700;
        font-size: 12px;
        position: relative;
        text-align: left;
        margin: 0;
        padding: 0;
        & span {
          font-size: 0.875rem;
          font-weight: 400;
          padding-right: 8px;
        }
      }
    }
  }
  &-title-small {
    font-weight: 700;
    padding: 15px 23px 5px 63px;
    font-size: 14px;
    text-align: left;
  }
  &-top-column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding-left: 5px;
  }
  &-top-row {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
  }

  &-column {
    display: flex;
    flex-direction: column;
    margin-right: 2vw;

    &-left {
      display: flex;
      flex-direction: column;
      margin-right: 3vw;
      text-align: right;
    }
    &-right {
      display: flex;
      flex-direction: column;
      margin-right: 3vw;
      text-align: left;
    }
    &-select {
      margin-right: 3vw;
      text-align: left;
      min-width: 200px;
    }
  }
  &-row {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 15px;
    align-items: center;
    &-block {
      display: inline-block;
      text-align: left;
      margin-top: 15px;
    }
    &-totals {
      padding: 10px 40px 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-around;
      width: 100%;
      background-color: #fff;
      border: none;
    }
    &-subtotals {
      padding: 10px 40px 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 80%;
      background-color: #fbfafa;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      box-shadow: none;
    }
  }
  &-responsive-row {
    display: flex;
    flex-direction: row;
    @include responsiveMinMax($desktopMin, $desktopMax) {
      flex-wrap: wrap;
    }
  }
  // &-responsive-row-space {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;
  // }
  &-avatar-img {
    border-radius: 50%;
    border: 2px solid #dfe0eb;
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}
.two-column {
  width: 115px !important;
}
.avatar-row {
  display: flex;
  align-items: center;
}
.cardhold-btn {
  margin-top: 10px;
}

@include mediaMd {
  .detail {
    &-section {
      padding: 0 20px;
      text-align: left;
      margin-bottom: 15px;
      width: 100%;
      &-title {
        font-weight: 700;
        padding: 15px 23px 5px 43px;
        font-size: 12px;
        // position: relative;
        // margin: 20px 45% 20px 0;
        text-align: left;

        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          // margin: 15px 0 0;
          padding: 20px 20px;
        }
        &-status {
          width: 80%;
          font-weight: 700;
          font-size: 12px;
          position: relative;
          text-align: left;
          margin: 0;
          padding: 0;
          & span {
            font-size: 0.875rem;
            font-weight: 400;
            padding-right: 8px;
          }
        }
      }
    }
    &-title-small {
      width: 100%;
      font-weight: 700;
      padding: 15px 23px 15px 63px;
      font-size: 12px;
      text-align: left;
    }
    &-top-column {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 5px;
    }
    &-column {
      display: flex;
      flex-direction: column;
      margin-right: 2vw;

      &-left {
        display: flex;
        flex-direction: column;
        margin-right: 3vw;
        text-align: right;
      }
      &-right {
        display: flex;
        flex-direction: column;
        margin-right: 3vw;
        text-align: left;
      }
    }
    &-row {
      display: flex;
      flex-direction: row;
      text-align: left;
      margin-top: 15px;
      &-totals {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        // background-color: #fbfafa;
        // border: 1px solid #dcdcdc;
        // border-radius: 4px;
        // box-shadow: none;
      }
    }
    &-responsive-row {
      display: flex;
      flex-direction: column;
    }
  }
  .two-column {
    width: 120px !important;
  }
}

@include responsiveMax($iPhone5) {
  .detail-row-totals {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding: 5px 20px 15px;
  }
}
// @include responsiveMin($iPad) {
//   .detail-row-totals {
//     // display: flex;
//     // flex-direction: column;
//     align-items: flex-start;
//     width: 80%;
//     // margin: 0 auto;
//     // padding: 5px 20px 15px;
//   }
// }
