// mobile first styling for iPhone X
.modal-main-receipt {
  position: fixed;
  background: white;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 30px 10px;
}
.receipt-img-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

@include responsiveMinMax($desktopMin, $desktopMax) {
  .modal-main-receipt {
    position: fixed;
    background: white;
    text-align: center;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 30px 10px;
  }
}
@include responsiveMinMax($desktopMax, $Xldesktop) {
  .modal-main-receipt {
    position: fixed;
    background: white;
    text-align: center;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 30px 10px;
  }
}

// @include responsiveMin($Xldesktop) {
//   .modal-main-receipt {
//     position: fixed;
//     background: white;
//     text-align: center;
//     width: 80%;
//     height: 80%;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     padding: 30px 30px 10px;
//   }
//   .receipt-img-modal {
//     height: 90%;
//     width: 90%;
//   }
// }
